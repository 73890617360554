import axios from "axios";
import utils from "../utils/index";

function getServerUrlPrefix() {
    return "/api/v1";
}

/**
 * Helper function that handled the actual requests and encrypts them and decrypts the result (if applicable)
 *
 * @param {string} method
 * @param {string} endpoint
 * @param {object} data
 * @param {object} headers
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function call(method, endpoint, data, headers) {
    const url = getServerUrlPrefix() + endpoint;

    headers["X-Requested-With"] = "XMLHttpRequest";
    headers["X-CSRFTOKEN"] = utils.getCSRFToken();
    
    return new Promise((resolve, reject) => {
        axios({
            method,
            url,
            data,
            headers,
        })
            .then((data) => {
                resolve(data);
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                        // OIDC ID token expired, lets refresh
                        // expiration interval can be changed with OIDC_RENEW_ID_TOKEN_EXPIRY_SECONDS
                        location.reload();
                        // triggered redirect so we don't have to resolve the promise
                        return;
                    }
                    console.log(error.response);
                    return reject(error.response);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log("Error", error.message);
                }
                console.log(error.config);
                reject({ errors: ["SERVER_OFFLINE"] });
            });
    });
}

/**
 * GET: Returns the server info
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function info() {
    const endpoint = "/info/";
    const connectionType = "GET";
    const data = null;
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * GET: Returns projects
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readCountries() {
    const endpoint = "/country/";
    const connectionType = "GET";
    const data = null;
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * GET: Returns states
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readStates() {
    const endpoint = "/state/";
    const connectionType = "GET";
    const data = null;
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * GET: Returns projects
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readPricing(billingAccountId, projectId) {
    const endpoint = "/pricing/" + billingAccountId + '/' + (!projectId ? "" : projectId + "/");
    const connectionType = "GET";
    const data = null;
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * GET: Returns states
 *
 * @param projectId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readPsonoSaasRegions(projectId) {
    const endpoint = "/psono-saas-region/";
    const connectionType = "GET";
    const data = null;
    const headers = {};

    const params = !projectId
        ? ""
        : "?" +
          new URLSearchParams({
              project_id: projectId,
          }).toString();

    return call(connectionType, endpoint + params, data, headers);
}

/**
 * GET: Returns billing accounts
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readBillingAccounts(billingAccountId) {
    const endpoint = "/billing-account/" + (!billingAccountId ? "" : billingAccountId + "/");
    const connectionType = "GET";
    const data = null;
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * POST: Creates a billing account
 *
 * @param {string} name
 * @param {string} companyName
 * @param {string} address1
 * @param {string} address2
 * @param {string} zipCode
 * @param {string} city
 * @param {string} country
 * @param {string} state
 * @param {string} vatRegNo
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function createBillingAccount(name, companyName, address1, address2, zipCode, city, country, state, vatRegNo) {
    const endpoint = "/billing-account/";
    const connectionType = "POST";
    const data = {
        name: name,
        company_name: companyName,
        address1: address1,
        address2: address2,
        zip_code: zipCode,
        city: city,
        country: country,
        state: state,
        vat_reg_no: vatRegNo,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * PUT: Updates a billing account
 *
 * @param {string} billingAccountId
 * @param {string} name
 * @param {string} companyName
 * @param {string} address1
 * @param {string} address2
 * @param {string} zipCode
 * @param {string} city
 * @param {string} state
 * @param {string} vatRegNo
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function updateBillingAccount(billingAccountId, name, companyName, address1, address2, zipCode, city, state, vatRegNo) {
    const endpoint = "/billing-account/";
    const connectionType = "PUT";
    const data = {
        billing_account_id: billingAccountId,
        name: name,
        company_name: companyName,
        address1: address1,
        address2: address2,
        zip_code: zipCode,
        city: city,
        state: state,
        vat_reg_no: vatRegNo,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * DELETE: Deletes a billing account
 *
 * @param {string} billingAccountId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function deleteBillingAccount(billingAccountId) {
    const endpoint = "/billing-account/";
    const connectionType = "DELETE";
    const data = {
        billing_account_id: billingAccountId,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * GET: Returns billing account users
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readBillingAccountInviteUsers(billingAccountId, billingAccountInviteUserId) {
    const endpoint = "/billing-account-invite-user/" + (!billingAccountInviteUserId ? "" : billingAccountInviteUserId + "/");
    const connectionType = "GET";
    const data = !billingAccountId ? null : { billing_account_id: billingAccountId };
    const headers = {};

    const params = !billingAccountId
        ? ""
        : "?" +
          new URLSearchParams({
              billing_account_id: billingAccountId,
          }).toString();

    return call(connectionType, endpoint + params, data, headers);
}

/**
 * POST: Creates a billing account invite
 *
 * @param billingAccountId
 * @param email
 * @param billingAccountRoleId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function createBillingAccountInviteUser(billingAccountId, email, billingAccountRoleId) {
    const endpoint = "/billing-account-invite-user/";
    const connectionType = "POST";
    const data = {
        billing_account_id: billingAccountId,
        email: email,
        billing_account_role_id: billingAccountRoleId,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * POST: Accepts a billing account invite
 *
 * @param billingAccountInviteId
 * @param billingAccountInviteSecret
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function acceptBillingAccountInviteUsers(billingAccountInviteId, billingAccountInviteSecret) {
    const endpoint = "/billing-account-invite-user/";
    const connectionType = "PUT";
    const data = {
        billing_account_invite_id: billingAccountInviteId,
        billing_account_invite_secret: billingAccountInviteSecret,
        state: "accepted",
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * POST: Rejects a billing account invite
 *
 * @param billingAccountInviteId
 * @param billingAccountInviteSecret
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function rejectBillingAccountInviteUsers(billingAccountInviteId, billingAccountInviteSecret) {
    const endpoint = "/billing-account-invite-user/";
    const connectionType = "PUT";
    const data = {
        billing_account_invite_id: billingAccountInviteId,
        billing_account_invite_secret: billingAccountInviteSecret,
        state: "rejected",
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * DELETE: Deletes a billing account invite
 *
 * @param {string} billingAccountInviteId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function deleteBillingAccountInviteUser(billingAccountInviteId) {
    const endpoint = "/billing-account-invite-user/";
    const connectionType = "DELETE";
    const data = {
        billing_account_invite_id: billingAccountInviteId,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * GET: Returns billing account users permission
 *
 * @param billingAccountPermissionUserId
 * @param billingAccountId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readBillingAccountPermissionUsers(billingAccountPermissionUserId, billingAccountId) {
    const endpoint = "/billing-account-permission-user/" + (!billingAccountPermissionUserId ? "" : billingAccountPermissionUserId + "/");
    const connectionType = "GET";
    const data = !billingAccountId ? null : { billing_account_id: billingAccountId };
    const headers = {};

    const params = !billingAccountId
        ? ""
        : "?" +
          new URLSearchParams({
              billing_account_id: billingAccountId,
          }).toString();

    return call(connectionType, endpoint + params, data, headers);
}

/**
 * POST: Creates a billing account permission
 *
 * @param billingAccountId
 * @param email
 * @param billingAccountRoleId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function createBillingAccountPermissionUser(billingAccountId, email, billingAccountRoleId) {
    const endpoint = "/billing-account-permission-user/";
    const connectionType = "POST";
    const data = {
        billing_account_id: billingAccountId,
        email: email,
        billing_account_role_id: billingAccountRoleId,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * PUT: Updates a billing account user permission
 *
 * @param {string} billingAccountPermissionUserId
 * @param {string} billingAccountRoleId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function updateBillingAccountPermissionUser(billingAccountPermissionUserId, billingAccountRoleId) {
    const endpoint = "/billing-account-permission-user/";
    const connectionType = "PUT";
    const data = {
        billing_account_permission_user_id: billingAccountPermissionUserId,
        billing_account_role_id: billingAccountRoleId,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * DELETE: Deletes a billing account user permission
 *
 * @param {string} billingAccountPermissionUserId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function deleteBillingAccountPermissionUser(billingAccountPermissionUserId) {
    const endpoint = "/billing-account-permission-user/";
    const connectionType = "DELETE";
    const data = {
        billing_account_permission_user_id: billingAccountPermissionUserId,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * GET: Returns project users
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readProjectInviteUsers(projectId, projectInviteUserId) {
    const endpoint = "/project-invite-user/" + (!projectInviteUserId ? "" : projectInviteUserId + "/");
    const connectionType = "GET";
    const data = !projectId ? null : { project_id: projectId };
    const headers = {};

    const params = !projectId
        ? ""
        : "?" +
          new URLSearchParams({
              project_id: projectId,
          }).toString();

    return call(connectionType, endpoint + params, data, headers);
}

/**
 * POST: Creates a project invite
 *
 * @param projectId
 * @param email
 * @param projectRoleId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function createProjectInviteUser(projectId, email, projectRoleId) {
    const endpoint = "/project-invite-user/";
    const connectionType = "POST";
    const data = {
        project_id: projectId,
        email: email,
        project_role_id: projectRoleId,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * POST: Accepts a project invite
 *
 * @param projectInviteId
 * @param projectInviteSecret
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function acceptProjectInviteUsers(projectInviteId, projectInviteSecret) {
    const endpoint = "/project-invite-user/";
    const connectionType = "PUT";
    const data = {
        project_invite_id: projectInviteId,
        project_invite_secret: projectInviteSecret,
        state: "accepted",
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * POST: Rejects a project invite
 *
 * @param projectInviteId
 * @param projectInviteSecret
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function rejectProjectInviteUsers(projectInviteId, projectInviteSecret) {
    const endpoint = "/project-invite-user/";
    const connectionType = "PUT";
    const data = {
        project_invite_id: projectInviteId,
        project_invite_secret: projectInviteSecret,
        state: "rejected",
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * DELETE: Deletes a project invite
 *
 * @param {string} projectInviteId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function deleteProjectInviteUser(projectInviteId) {
    const endpoint = "/project-invite-user/";
    const connectionType = "DELETE";
    const data = {
        project_invite_id: projectInviteId,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * GET: Returns project users permission
 *
 * @param projectPermissionUserId
 * @param projectId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readProjectPermissionUsers(projectPermissionUserId, projectId) {
    const endpoint = "/project-permission-user/" + (!projectPermissionUserId ? "" : projectPermissionUserId + "/");
    const connectionType = "GET";
    const data = !projectId ? null : { project_id: projectId };
    const headers = {};

    const params = !projectId
        ? ""
        : "?" +
          new URLSearchParams({
              project_id: projectId,
          }).toString();

    return call(connectionType, endpoint + params, data, headers);
}

/**
 * POST: Creates a project permission
 *
 * @param projectId
 * @param email
 * @param projectRoleId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function createProjectPermissionUser(projectId, email, projectRoleId) {
    const endpoint = "/project-permission-user/";
    const connectionType = "POST";
    const data = {
        project_id: projectId,
        email: email,
        project_role_id: projectRoleId,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * PUT: Updates a project user permission
 *
 * @param {string} projectPermissionUserId
 * @param {string} projectRoleId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function updateProjectPermissionUser(projectPermissionUserId, projectRoleId) {
    const endpoint = "/project-permission-user/";
    const connectionType = "PUT";
    const data = {
        project_permission_user_id: projectPermissionUserId,
        project_role_id: projectRoleId,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * DELETE: Deletes a project user permission
 *
 * @param {string} projectPermissionUserId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function deleteProjectPermissionUser(projectPermissionUserId) {
    const endpoint = "/project-permission-user/";
    const connectionType = "DELETE";
    const data = {
        project_permission_user_id: projectPermissionUserId,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * GET: Returns all billing account roles
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readBillingAccountRoles() {
    const endpoint = "/billing-account-roles/";
    const connectionType = "GET";
    const data = null;
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * GET: Returns all project roles
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readProjectRoles() {
    const endpoint = "/project-roles/";
    const connectionType = "GET";
    const data = null;
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * GET: Returns payment methods
 *
 * @param {string} paymentMethodId
 * @param {string} billingAccountId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readPaymentMethods(paymentMethodId, billingAccountId) {
    const endpoint = "/payment-method/" + (!paymentMethodId ? "" : paymentMethodId + "/");
    const connectionType = "GET";
    const data = !billingAccountId ? null : { billing_account_id: billingAccountId };
    const headers = {};

    const params = !billingAccountId
        ? ""
        : "?" +
          new URLSearchParams({
              billing_account_id: billingAccountId,
          }).toString();

    return call(connectionType, endpoint + params, data, headers);
}

/**
 * POST: Creates a payment method
 *
 * @param {string} billingAccountId
 * @param {string} provider
 * @param {string} setupIntent
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function createPaymentMethod(billingAccountId, provider, setupIntent) {
    const endpoint = "/payment-method/";
    const connectionType = "POST";
    const data = {
        billing_account_id: billingAccountId,
        provider: provider,
        setup_intent: setupIntent,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * PUT: Updates a payment method
 *
 * @param {string} paymentMethodId
 * @param {boolean} primary
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function updatePaymentMethod(paymentMethodId, primary) {
    const endpoint = "/payment-method/";
    const connectionType = "PUT";
    const data = {
        payment_method_id: paymentMethodId,
        primary: primary,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * DELETE: Deletes a payment method
 *
 * @param {string} paymentMethodId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function deletePaymentMethod(paymentMethodId) {
    const endpoint = "/payment-method/";
    const connectionType = "DELETE";
    const data = {
        payment_method_id: paymentMethodId,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * GET: Returns invoices
 *
 * @param {string} billingAccountId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readInvoices(billingAccountId) {
    const endpoint = "/invoice/";
    const connectionType = "GET";
    const data = !billingAccountId ? null : { billing_account_id: billingAccountId };
    const headers = {};

    const params = !billingAccountId
        ? ""
        : "?" +
          new URLSearchParams({
              billing_account_id: billingAccountId,
          }).toString();

    return call(connectionType, endpoint + params, data, headers);
}

/**
 * POST: Returns the pdf content of an invoice
 *
 * @param {string} invoiceId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function downloadInvoice(invoiceId) {
    const endpoint = "/invoice-pdf/";
    const connectionType = "POST";
    const data = {
        invoice_id: invoiceId,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * POST: Trys to pay an invoice manually
 *
 * @param {string} invoiceId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function payInvoice(invoiceId) {
    const endpoint = "/invoice-payment/";
    const connectionType = "POST";
    const data = {
        invoice_id: invoiceId,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}


/**
 * GET: Reads all the infos of the current logged-in user
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readMe() {
    const endpoint = "/me/";
    const connectionType = "GET";
    const data = null;
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}


/**
 * PUT: Updates all the infos of the current logged-in user
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function updateMe(locale) {
    const endpoint = "/me/";
    const connectionType = "PUT";
    const data = {
        locale: locale,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}



/**
 * GET: Returns the paddle account
 *
 * @param billingAccountId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readPaddleAccount(billingAccountId) {
    const endpoint = `/billing-account/${billingAccountId}/paddle-account/`;
    const connectionType = "GET";
    const data = null;
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}


/**
 * POST: Creates a stripe intent
 *
 * @param {string} billingAccountId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function createStripeIntent(billingAccountId) {
    const endpoint = "/stripe-intent/";
    const connectionType = "POST";
    const data = {
        billing_account_id: billingAccountId,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * PUT: Notifies the backend to update a stripe payment
 *
 * @param {string} stripePaymentId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function updateStripePayment(stripePaymentId) {
    const endpoint = "/stripe-payment/";
    const connectionType = "PUT";
    const data = {
        stripe_payment_id: stripePaymentId,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * GET: Returns Psono SaaS instance
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readPsonoSaasLogs(psonoSaasInstanceId, startTime, endTime, query) {
    const endpoint = "/psono-saas-log/" + psonoSaasInstanceId + "/";
    const connectionType = "GET";
    const data = null;
    const headers = {};

    const params = "?" +
        new URLSearchParams({
            start_time: startTime.toISOString(),
            end_time: endTime.toISOString(),
            query: query,
        }).toString()


    return call(connectionType, endpoint + params, data, headers);
}

/**
 * GET: Returns Psono SaaS instance
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readPsonoSaasInstances(psonoSaasInstanceId, projectId) {
    const endpoint = "/psono-saas-instance/" + (!psonoSaasInstanceId ? "" : psonoSaasInstanceId + "/");
    const connectionType = "GET";
    const data = null;
    const headers = {};

    const params = !projectId
        ? ""
        : "?" +
          new URLSearchParams({
              project_id: projectId,
          }).toString();

    return call(connectionType, endpoint + params, data, headers);
}

/**
 * POST: Creates a Psono SaaS instance
 *
 * @param projectId
 * @param psonoSaasRegionId
 * @param name
 * @param slug
 * @param variation
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function createPsonoSaasInstance(projectId, psonoSaasRegionId, name, slug, variation) {
    const endpoint = "/psono-saas-instance/";
    const connectionType = "POST";
    const data = {
        project_id: projectId,
        psono_saas_region_id: psonoSaasRegionId,
        name: name,
        slug: slug,
        variation: variation,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * PUT: Updates a Psono SaaS instance
 *
 * @param {string} psonoSaasInstanceId
 * @param {string} cPortalConfigJson
 * @param {string} cWebclientConfigJson
 * @param {string} name
 * @param {number} sActivationLinkTimeValid
 * @param {boolean} sAllowLostPassword
 * @param {boolean} sAllowMultipleSessions
 * @param {boolean} sAllowRegistration
 * @param {boolean} sAllowUserSearchByEmail
 * @param {boolean} sAllowUserSearchByUsernamePartial
 * @param {string} sAllowedDomains
 * @param {string} sAllowedSecondFactors
 * @param {string} sAuthenticationMethods
 *
 * @param {string} sSplunkHost
 * @param {number} sSplunkPort
 * @param {string} sSplunkToken
 * @param {string} sSplunkIndex
 * @param {string} sSplunkProtocol
 * @param {boolean} sSplunkVerify
 * @param {string} sSplunkSourcetype
 *
 * @param {string} sS3LoggingBucket
 * @param {string} sS3LoggingAccessKeyId
 * @param {string} sS3LoggingSecretAccessKey
 *
 * @param {string} sLogstashTransport
 * @param {string} sLogstashHost
 * @param {number} sLogstashPort
 * @param {boolean} sLogstashSslEnabled
 * @param {boolean} sLogstashSslVerify
 *
 * @param {number} sAutoProlongationTokenTimeValid
 * @param {number} sCCentralSecurityReportSecurityRecurrenceInterval
 * @param {boolean} sCDisableApiKeys
 * @param {string} sCServerSecrets
 * @param {boolean} sCDisableDeleteAccount
 * @param {boolean} sCDisableEmergencyCodes
 * @param {boolean} sCDisableExport
 * @param {boolean} sCDisableExportOfSharedItems
 * @param {boolean} sCDisableFileRepositories
 * @param {boolean} sCDisableLinkShares
 * @param {boolean} sCDisableRecoveryCodes
 * @param {boolean} sCEnforce2fa
 * @param {boolean} sCDisableUnmanagedGroups
 * @param {boolean} sCEnforceCentralSecurityReports
 * @param {number} sCMinMasterPasswordComplexity
 * @param {number} sCMinMasterPasswordLength
 * @param {number} sCMaxOfflineCacheTimeValid
 * @param {string} sCIPRestrictions
 *
 * @param {number} sCPasswordGeneratorDefaultPasswordLength
 * @param {string} sCPasswordGeneratorDefaultLettersUppercase
 * @param {string} sCPasswordGeneratorDefaultLettersLowercase
 * @param {string} sCPasswordGeneratorDefaultNumbers
 * @param {string} sCPasswordGeneratorDefaultSpecialChars
 *
 * @param {number}sDefaultTokenTimeValid
 * @param {boolean} sDisableCentralSecurityReports
 * @param {number} sDisableLastPasswords
 * @param {string} sDuoIntegrationKey
 * @param {string} sDuoSecretKey
 * @param {string} sDuoApiHostname
 * @param {boolean} sEnforceMatchingUsernameAndEmail
 * @param {boolean} sFilesEnabled
 * @param {boolean} sLoggingAudit
 * @param {boolean} sFileserverHandlerEnabled
 * @param {number} sMaxApiKeyTokenTimeValid
 * @param {number} sMaxAppTokenTimeValid
 * @param {number} sMaxWebclientTokenTimeValid
 * @param {boolean} sMultifactorEnabled
 * @param {number} sRecoveryVerifierTimeValid
 * @param {string} sRegistrationEmailFilter
 * @param {string} sLdapgateway
 * @param {string} sScimConfigurations
 * @param {string} sSamlConfigurations
 * @param {string} sOidcConfigurations
 * @param {string} variation
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function updatePsonoSaasInstance(
    psonoSaasInstanceId,
    cPortalConfigJson,
    cWebclientConfigJson,
    name,
    sActivationLinkTimeValid,
    sAllowLostPassword,
    sAllowMultipleSessions,
    sAllowRegistration,
    sAllowUserSearchByEmail,
    sAllowUserSearchByUsernamePartial,
    sAllowedDomains,
    sAllowedSecondFactors,
    sAuthenticationMethods,

    sSplunkHost,
    sSplunkPort,
    sSplunkToken,
    sSplunkIndex,
    sSplunkProtocol,
    sSplunkVerify,
    sSplunkSourcetype,

    sS3LoggingBucket,
    sS3LoggingAccessKeyId,
    sS3LoggingSecretAccessKey,

    sLogstashTransport,
    sLogstashHost,
    sLogstashPort,
    sLogstashSslEnabled,
    sLogstashSslVerify,

    sAutoProlongationTokenTimeValid,
    sCCentralSecurityReportSecurityRecurrenceInterval,

    sCDisableApiKeys,
    sCServerSecrets,
    sCDisableDeleteAccount,
    sCDisableEmergencyCodes,
    sCDisableExport,
    sCDisableExportOfSharedItems,
    sCDisableFileRepositories,
    sCDisableLinkShares,
    sCDisableRecoveryCodes,
    sCEnforce2fa,
    sCDisableUnmanagedGroups,
    sCEnforceCentralSecurityReports,
    sCMinMasterPasswordComplexity,
    sCMinMasterPasswordLength,
    sCMaxOfflineCacheTimeValid,
    sCIPRestrictions,

    sCPasswordGeneratorDefaultPasswordLength,
    sCPasswordGeneratorDefaultLettersUppercase,
    sCPasswordGeneratorDefaultLettersLowercase,
    sCPasswordGeneratorDefaultNumbers,
    sCPasswordGeneratorDefaultSpecialChars,

    sDefaultTokenTimeValid,
    sDisableCentralSecurityReports,
    sDisableLastPasswords,
    sDuoIntegrationKey,
    sDuoSecretKey,
    sDuoApiHostname,
    sEnforceMatchingUsernameAndEmail,
    sFilesEnabled,
    sLoggingAudit,
    sFileserverHandlerEnabled,
    sMaxApiKeyTokenTimeValid,
    sMaxAppTokenTimeValid,
    sMaxWebclientTokenTimeValid,
    sMultifactorEnabled,
    sRecoveryVerifierTimeValid,
    sRegistrationEmailFilter,
    sLdapgateway,
    sScimConfigurations,
    sSamlConfigurations,
    sOidcConfigurations,
    variation
) {
    const endpoint = "/psono-saas-instance/";
    const connectionType = "PUT";
    const data = {
        psono_saas_instance_id: psonoSaasInstanceId,
        c_portal_config_json: cPortalConfigJson,
        c_webclient_config_json: cWebclientConfigJson,
        name: name,
        s_activation_link_time_valid: sActivationLinkTimeValid,
        s_allow_lost_password: sAllowLostPassword,
        s_allow_multiple_sessions: sAllowMultipleSessions,
        s_allow_registration: sAllowRegistration,
        s_allow_user_search_by_email: sAllowUserSearchByEmail,
        s_allow_user_search_by_username_partial: sAllowUserSearchByUsernamePartial,
        s_allowed_domains: sAllowedDomains,
        s_allowed_second_factors: sAllowedSecondFactors,
        s_authentication_methods: sAuthenticationMethods,

        s_splunk_host: sSplunkHost,
        s_splunk_port: sSplunkPort,
        s_splunk_token: sSplunkToken,
        s_splunk_index: sSplunkIndex,
        s_splunk_protocol: sSplunkProtocol,
        s_splunk_verify: sSplunkVerify,
        s_splunk_sourcetype: sSplunkSourcetype,

        s_s3_logging_bucket: sS3LoggingBucket,
        s_s3_logging_access_key_id: sS3LoggingAccessKeyId,
        s_s3_logging_secret_access_key: sS3LoggingSecretAccessKey,

        s_logstash_transport: sLogstashTransport,
        s_logstash_host: sLogstashHost,
        s_logstash_port: sLogstashPort,
        s_logstash_ssl_enabled: sLogstashSslEnabled,
        s_logstash_ssl_verify: sLogstashSslVerify,

        s_auto_prolongation_token_time_valid: sAutoProlongationTokenTimeValid,
        s_c_central_security_report_security_recurrence_interval: sCCentralSecurityReportSecurityRecurrenceInterval,

        s_c_disable_api_keys: sCDisableApiKeys,
        s_c_server_secrets: sCServerSecrets,
        s_c_disable_delete_account: sCDisableDeleteAccount,
        s_c_disable_emergency_codes: sCDisableEmergencyCodes,
        s_c_disable_export: sCDisableExport,
        s_c_disable_export_of_shared_items: sCDisableExportOfSharedItems,
        s_c_disable_file_repositories: sCDisableFileRepositories,
        s_c_disable_link_shares: sCDisableLinkShares,
        s_c_disable_recovery_codes: sCDisableRecoveryCodes,
        s_c_enforce_2fa: sCEnforce2fa,
        s_c_disable_unmanaged_groups: sCDisableUnmanagedGroups,
        s_c_enforce_central_security_reports: sCEnforceCentralSecurityReports,
        s_c_min_master_password_complexity: sCMinMasterPasswordComplexity,
        s_c_min_master_password_length: sCMinMasterPasswordLength,
        s_c_max_offline_cache_time_valid: sCMaxOfflineCacheTimeValid,
        s_c_ip_restrictions: sCIPRestrictions,

        s_c_password_generator_default_password_length: sCPasswordGeneratorDefaultPasswordLength,
        s_c_password_generator_default_letters_uppercase: sCPasswordGeneratorDefaultLettersUppercase,
        s_c_password_generator_default_letters_lowercase: sCPasswordGeneratorDefaultLettersLowercase,
        s_c_password_generator_default_numbers: sCPasswordGeneratorDefaultNumbers,
        s_c_password_generator_default_special_chars: sCPasswordGeneratorDefaultSpecialChars,

        s_default_token_time_valid: sDefaultTokenTimeValid,
        s_disable_central_security_reports: sDisableCentralSecurityReports,
        s_disable_last_passwords: sDisableLastPasswords,
        s_duo_integration_key: sDuoIntegrationKey,
        s_duo_secret_key: sDuoSecretKey,
        s_duo_api_hostname: sDuoApiHostname,
        s_enforce_matching_username_and_email: sEnforceMatchingUsernameAndEmail,
        s_files_enabled: sFilesEnabled,
        s_logging_audit: sLoggingAudit,
        s_fileserver_handler_enabled: sFileserverHandlerEnabled,
        s_max_api_key_token_time_valid: sMaxApiKeyTokenTimeValid,
        s_max_app_token_time_valid: sMaxAppTokenTimeValid,
        s_max_webclient_token_time_valid: sMaxWebclientTokenTimeValid,
        s_multifactor_enabled: sMultifactorEnabled,
        s_recovery_verifier_time_valid: sRecoveryVerifierTimeValid,
        s_registration_email_filter: sRegistrationEmailFilter,
        s_ldapgateway: sLdapgateway,
        s_scim_configurations: sScimConfigurations,
        s_saml_configurations: sSamlConfigurations,
        s_oidc_configurations: sOidcConfigurations,
        variation: variation,
    };
    console.log(data)
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * DELETE: Deletes a Psono SaaS instance
 *
 * @param {string} psonoSaasInstanceId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function deletePsonoSaasInstance(psonoSaasInstanceId) {
    const endpoint = "/psono-saas-instance/";
    const connectionType = "DELETE";
    const data = {
        psono_saas_instance_id: psonoSaasInstanceId,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * GET: Returns Psono license
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readPsonoLicenses(psonoLicenseId, projectId) {
    const endpoint = "/psono-license/" + (!psonoLicenseId ? "" : psonoLicenseId + "/");
    const connectionType = "GET";
    const data = null;
    const headers = {};

    const params = !projectId
        ? ""
        : "?" +
          new URLSearchParams({
              project_id: projectId,
          }).toString();

    return call(connectionType, endpoint + params, data, headers);
}

/**
 * POST: Creates a Psono license
 *
 * @param projectId
 * @param name
 * @param publicKey
 * @param variation
 * @param {boolean} autoRenew
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function createPsonoLicense(projectId, name, publicKey, variation, autoRenew) {
    const endpoint = "/psono-license/";
    const connectionType = "POST";
    const data = {
        project_id: projectId,
        name: name,
        public_key: publicKey,
        variation: variation,
        auto_renew: autoRenew,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * PUT: Updates a Psono license
 *
 * @param {string} psonoLicenseId
 * @param {string} name
 * @param {string} variation
 * @param {boolean} autoRenew
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function updatePsonoLicense(psonoLicenseId, name, variation, autoRenew) {
    const endpoint = "/psono-license/";
    const connectionType = "PUT";
    const data = {
        psono_license_id: psonoLicenseId,
        name: name,
        variation: variation,
        auto_renew: autoRenew,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * GET: Returns projects
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readProjects(projectId) {
    const endpoint = "/project/" + (!projectId ? "" : projectId + "/");
    const connectionType = "GET";
    const data = null;
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * POST: Creates a project
 *
 * @param {string} name
 * @param {string} billingAccountId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function createProject(name, billingAccountId) {
    const endpoint = "/project/";
    const connectionType = "POST";
    const data = {
        name: name,
        billing_account_id: billingAccountId,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * PUT: Updates a project
 *
 * @param {string} projectId
 * @param {string} name
 * @param {string} billingAccountId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function updateProject(projectId, name, billingAccountId) {
    const endpoint = "/project/";
    const connectionType = "PUT";
    const data = {
        project_id: projectId,
        name: name,
        billing_account_id: billingAccountId,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * DELETE: Deletes a project
 *
 * @param {string} projectId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function deleteProject(projectId) {
    const endpoint = "/project/";
    const connectionType = "DELETE";
    const data = {
        project_id: projectId,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * GET: Returns Psono custom domains for a saas custom domain
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function readPsonoSaasCustomDomains(psonoSaasInstanceId) {
    const endpoint = "/psono-saas-custom-domain/" + (!psonoSaasInstanceId ? "" : psonoSaasInstanceId + "/");
    const connectionType = "GET";
    const data = null;
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * POST: Creates a Psono SaaS custom domain
 *
 * @param psonoSaasInstanceId
 * @param domain
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function createPsonoSaasCustomDomain(psonoSaasInstanceId, domain) {
    const endpoint = "/psono-saas-custom-domain/";
    const connectionType = "POST";
    const data = {
        psono_saas_instance_id: psonoSaasInstanceId,
        domain: domain,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * PUT: Updates a Psono SaaS custom domain
 *
 * @param {string} psonoSaasInstanceId
 * @param {string} domain
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function updatePsonoSaasCustomDomain(
    psonoSaasInstanceId,
    domain
) {
    const endpoint = "/psono-saas-custom-domain/";
    const connectionType = "PUT";
    const data = {
        psono_saas_instance_id: psonoSaasInstanceId,
        domain: domain,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

/**
 * DELETE: Deletes a Psono SaaS custom domain
 *
 * @param {string} psonoSaasInstanceId
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
function deletePsonoSaasCustomDomain(psonoSaasInstanceId) {
    const endpoint = "/psono-saas-custom-domain/";
    const connectionType = "DELETE";
    const data = {
        psono_saas_instance_id: psonoSaasInstanceId,
    };
    const headers = {};

    return call(connectionType, endpoint, data, headers);
}

const service = {
    info,
    readCountries,
    readStates,
    readPricing,
    readPsonoSaasRegions,
    readProjects,
    createProject,
    updateProject,
    deleteProject,
    readBillingAccounts,
    createBillingAccount,
    updateBillingAccount,
    deleteBillingAccount,
    readBillingAccountInviteUsers,
    createBillingAccountInviteUser,
    acceptBillingAccountInviteUsers,
    rejectBillingAccountInviteUsers,
    deleteBillingAccountInviteUser,
    readBillingAccountPermissionUsers,
    createBillingAccountPermissionUser,
    updateBillingAccountPermissionUser,
    deleteBillingAccountPermissionUser,
    readProjectInviteUsers,
    createProjectInviteUser,
    acceptProjectInviteUsers,
    rejectProjectInviteUsers,
    deleteProjectInviteUser,
    readProjectPermissionUsers,
    createProjectPermissionUser,
    updateProjectPermissionUser,
    deleteProjectPermissionUser,
    readBillingAccountRoles,
    readProjectRoles,
    readPaymentMethods,
    createPaymentMethod,
    updatePaymentMethod,
    deletePaymentMethod,
    readInvoices,
    downloadInvoice,
    payInvoice,
    readMe,
    updateMe,
    readPaddleAccount,
    createStripeIntent,
    updateStripePayment,
    readPsonoSaasLogs,
    readPsonoSaasInstances,
    createPsonoSaasInstance,
    updatePsonoSaasInstance,
    deletePsonoSaasInstance,
    readPsonoLicenses,
    createPsonoLicense,
    updatePsonoLicense,
    readPsonoSaasCustomDomains,
    createPsonoSaasCustomDomain,
    updatePsonoSaasCustomDomain,
    deletePsonoSaasCustomDomain,
};

export default service;
