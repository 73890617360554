import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Route, Switch} from "react-router-dom";

import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import makeStyles from '@mui/styles/makeStyles';

import Sidebar from "../components/sidebar";
import SelectProject from "./_selectProject";
import BillingAccounts from "./billing-accounts";
import Projects from "./projects";
import PaymentMethods from "./payment-methods";
import PsonoSaasInstance from "./psono-saas-instance";
import PsonoLicense from "./psono-license";
import InviteBillingAccount from "./invite-billing-account";
import InviteProject from "./invite-project";
import Invoices from "./invoice";
import NavigationBar from "../components/navigationbar";
import Footer from "../components/footer";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: "auto",
    },
    content: {
        flexGrow: 1,
    },
}));

function ClippedDrawer(props) {
    const [project, setProject] = useState(null);
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <NavigationBar onProjectSelect={setProject} />
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <Toolbar />
                <Sidebar />
            </Drawer>
            <main className={classes.content}>
                <Toolbar />
                <Switch>
                    <Route path="/psono-saas-instance/">{project ? <PsonoSaasInstance project={project} /> : <SelectProject />}</Route>
                    <Route path="/psono-license/">{project ? <PsonoLicense project={project} /> : <SelectProject />}</Route>
                    <Route path="/invoices/">
                        <Invoices />
                    </Route>
                    <Route path="/invite-billing-account/">
                        <InviteBillingAccount />
                    </Route>
                    <Route path="/billing-accounts/">
                        <BillingAccounts />
                    </Route>
                    <Route path="/invite-project/">
                        <InviteProject />
                    </Route>
                    <Route path="/projects/">
                        <Projects />
                    </Route>
                    <Route path="/payment-methods/">
                        <PaymentMethods />
                    </Route>
                </Switch>
            </main>
            <Footer />
        </div>
    );

}
export default ClippedDrawer;
