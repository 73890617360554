import React, { useState, useReducer } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Redirect } from "react-router";

import AppBar from "@mui/material/AppBar";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from '@mui/icons-material/Edit';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ListAltIcon from '@mui/icons-material/ListAlt';

import PsonoSaasRegionSelectField from "../../components/psono-saas-region-select-field";
import PsonoSaasInstanceService from "../../services/psono-saas-instances";
import pricingService from "../../services/pricing";
import converterService from "../../services/converter";
import utils from "../../utils";
import ContentBody from "../../container/content-body";
import ContentHeader from "../../container/content-header";
import TabPanel from "../../components/tab-panel";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "inline",
    },
    h2: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1),
    },
    buttons: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    backButton: {
        marginTop: "-12px",
        marginBottom: "-12px",
        marginLeft: "-12px",
    },
    helperText: {
        marginTop: "-8px",
    },
    textField: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "440px",
        },
    },
}));

function PsonoSaasInstanceDetail({ project }) {


    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const params = useParams();

    const [auditLoggingTab, setAuditLoggingTab] = React.useState(0);
    const [state, setState] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            psonoSaasRegionId: null,
            pricing: {},
            name: "",
            adminPortalUrl: "",
            webclientUrl: "",
            defaultAdminUsername: "",
            defaultAdminPassword: "",
            slug: "",
            cPortalConfigJson: "{}",
            cWebclientConfigJson: "{}",
            variation: "u25",
            paidTill: null,
            sAllowLostPassword: false,
            sAllowRegistration: false,
            sAllowMultipleSessions: false,
            sCDisableApiKeys: false,
            sCServerSecrets: 'auto',
            sCDisableDeleteAccount: false,
            sCDisableEmergencyCodes: false,
            sCDisableExport: false,
            sCDisableExportOfSharedItems: false,
            sCDisableFileRepositories: false,
            sCDisableLinkShares: false,
            sCDisableRecoveryCodes: false,
            sCEnforceCentralSecurityReports: false,
            sCEnforce2fa: false,
            sCDisableUnmanagedGroups: false,
            sCCentralSecurityReportSecurityRecurrenceInterval: 0,
            sAutoProlongationTokenTimeValid: 0,
            sCMinMasterPasswordComplexity: 0,
            sCMinMasterPasswordLength: 0,
            sCMaxOfflineCacheTimeValid: 0,
            sCPasswordGeneratorDefaultPasswordLength: 0,
            sCIPRestrictions: [],
            sCPasswordGeneratorDefaultLettersUppercase: "",
            sCPasswordGeneratorDefaultLettersLowercase: "",
            sCPasswordGeneratorDefaultNumbers: "",
            sCPasswordGeneratorDefaultSpecialChars: "",

            sAllowedDomains: "",
            sAllowedSecondFactors: "",
            sAuthenticationMethods: "",

            sSplunkHost: "",
            sSplunkPort: 0,
            sSplunkToken: "",
            sSplunkIndex: "",
            sSplunkProtocol: "",
            sSplunkVerify: false,
            sSplunkSourcetype: "",

            sS3LoggingBucket: "",
            sS3LoggingAccessKeyId: "",
            sS3LoggingSecretAccessKey: "",

            sLogstashTransport: "",
            sLogstashHost: "",
            sLogstashPort: 0,
            sLogstashSslEnabled: false,
            sLogstashSslVerify: false,

            sMaxAppTokenTimeValid: 0,
            sMaxWebclientTokenTimeValid: 0,
            sMaxApiKeyTokenTimeValid: 0,
            sDisableLastPasswords: 0,

            sDuoIntegrationKey: "",
            sDuoSecretKey: "",
            sDuoApiHostname: "",

            sActivationLinkTimeValid: 0,
            sDefaultTokenTimeValid: 0,
            sAllowUserSearchByEmail: false,
            sAllowUserSearchByUsernamePartial: false,
            sDisableCentralSecurityReports: false,
            sEnforceMatchingUsernameAndEmail: false,
            sFilesEnabled: false,
            sLoggingAudit: false,
            sFileserverHandlerEnabled: false,
            sMultifactorEnabled: false,
            sRecoveryVerifierTimeValid: 0,
            sRegistrationEmailFilter: 0,
            instanceState: "enabled",
            sLdapgateway: "",
            sScimConfigurations: "",
            sSamlConfigurations: "",
            sOidcConfigurations: "",
            errors: {},
        }
    )

    React.useEffect(() => {
        PsonoSaasInstanceService.getPsonoSaasInstance(params.id).then(
            (psonoSaasDetails) => {
                let modifiedSLdapgateway =  converterService.jsonToYaml(psonoSaasDetails.s_ldapgateway);
                let modifiedSScimConfigurations =  converterService.jsonToYaml(psonoSaasDetails.s_scim_configurations);
                let modifiedSSamlConfigurations =  converterService.jsonToYaml(psonoSaasDetails.s_saml_configurations);
                let modifiedSOidcConfigurations = converterService.jsonToYaml(psonoSaasDetails.s_oidc_configurations);

                setState({
                    psonoSaasRegionId: psonoSaasDetails.psono_saas_region_id,
                    name: psonoSaasDetails.name,
                    adminPortalUrl: psonoSaasDetails.admin_portal_url,
                    webclientUrl: psonoSaasDetails.webclient_url,
                    defaultAdminUsername: psonoSaasDetails.default_admin_username,
                    defaultAdminPassword: psonoSaasDetails.default_admin_password,
                    slug: psonoSaasDetails.slug,
                    cPortalConfigJson: psonoSaasDetails.c_portal_config_json,
                    cWebclientConfigJson: psonoSaasDetails.c_webclient_config_json,
                    variation: psonoSaasDetails.variation,
                    paidTill: new Date(psonoSaasDetails.paid_till),
                    sAllowLostPassword: psonoSaasDetails.s_allow_lost_password,
                    sAllowRegistration: psonoSaasDetails.s_allow_registration,
                    sAllowMultipleSessions: psonoSaasDetails.s_allow_multiple_sessions,
                    sCDisableApiKeys: psonoSaasDetails.s_c_disable_api_keys,
                    sCServerSecrets: psonoSaasDetails.s_c_server_secrets,
                    sCDisableDeleteAccount: psonoSaasDetails.s_c_disable_delete_account,
                    sCDisableEmergencyCodes: psonoSaasDetails.s_c_disable_emergency_codes,
                    sCDisableExport: psonoSaasDetails.s_c_disable_export,
                    sCDisableExportOfSharedItems: psonoSaasDetails.s_c_disable_export_of_shared_items,
                    sCDisableFileRepositories: psonoSaasDetails.s_c_disable_file_repositories,
                    sCDisableLinkShares: psonoSaasDetails.s_c_disable_link_shares,
                    sCDisableRecoveryCodes: psonoSaasDetails.s_c_disable_recovery_codes,
                    sCEnforceCentralSecurityReports: psonoSaasDetails.s_c_enforce_central_security_reports,
                    sCEnforce2fa: psonoSaasDetails.s_c_enforce_2fa,
                    sCDisableUnmanagedGroups: psonoSaasDetails.s_c_disable_unmanaged_groups,
                    sCCentralSecurityReportSecurityRecurrenceInterval: psonoSaasDetails.s_c_central_security_report_security_recurrence_interval,
                    sAutoProlongationTokenTimeValid: psonoSaasDetails.s_auto_prolongation_token_time_valid,
                    sCMinMasterPasswordComplexity: psonoSaasDetails.s_c_min_master_password_complexity,
                    sCMinMasterPasswordLength: psonoSaasDetails.s_c_min_master_password_length,
                    sCMaxOfflineCacheTimeValid: psonoSaasDetails.s_c_max_offline_cache_time_valid,
                    sCIPRestrictions: psonoSaasDetails.s_c_ip_restrictions.split(
                                                                            /[\s,;]+/
                                                                        ),
                    sCPasswordGeneratorDefaultPasswordLength: psonoSaasDetails.s_c_password_generator_default_password_length,
                    sCPasswordGeneratorDefaultLettersLowercase: psonoSaasDetails.s_c_password_generator_default_letters_lowercase,
                    sCPasswordGeneratorDefaultNumbers: psonoSaasDetails.s_c_password_generator_default_numbers,
                    sCPasswordGeneratorDefaultSpecialChars: psonoSaasDetails.s_c_password_generator_default_special_chars,

                    sAllowedDomains: psonoSaasDetails.s_allowed_domains,
                    sAllowedSecondFactors: psonoSaasDetails.s_allowed_second_factors,
                    sAuthenticationMethods: psonoSaasDetails.s_authentication_methods,

                    sSplunkHost: psonoSaasDetails.s_splunk_host,
                    sSplunkPort: psonoSaasDetails.s_splunk_port,
                    sSplunkToken: psonoSaasDetails.s_splunk_token,
                    sSplunkIndex: psonoSaasDetails.s_splunk_index,
                    sSplunkProtocol: psonoSaasDetails.s_splunk_protocol,
                    sSplunkVerify: psonoSaasDetails.s_splunk_verify,
                    sSplunkSourcetype: psonoSaasDetails.s_splunk_sourcetype,

                    sS3LoggingBucket: psonoSaasDetails.s_s3_logging_bucket,
                    sS3LoggingAccessKeyId: psonoSaasDetails.s_s3_logging_access_key_id,
                    sS3LoggingSecretAccessKey: psonoSaasDetails.s_s3_logging_secret_access_key,

                    sLogstashTransport: psonoSaasDetails.s_logstash_transport,
                    sLogstashHost: psonoSaasDetails.s_logstash_host,
                    sLogstashPort: psonoSaasDetails.s_logstash_port,
                    sLogstashSslEnabled: psonoSaasDetails.s_logstash_ssl_enabled,
                    sLogstashSslVerify: psonoSaasDetails.s_logstash_ssl_verify,

                    sMaxAppTokenTimeValid: psonoSaasDetails.s_max_app_token_time_valid,
                    sMaxWebclientTokenTimeValid: psonoSaasDetails.s_max_webclient_token_time_valid,
                    sMaxApiKeyTokenTimeValid: psonoSaasDetails.s_max_api_key_token_time_valid,
                    sDisableLastPasswords: psonoSaasDetails.s_disable_last_passwords,

                    sDuoIntegrationKey: psonoSaasDetails.s_duo_integration_key,
                    sDuoSecretKey: psonoSaasDetails.s_duo_secret_key,
                    sDuoApiHostname: psonoSaasDetails.s_duo_api_hostname,

                    sActivationLinkTimeValid: psonoSaasDetails.s_activation_link_time_valid,
                    sDefaultTokenTimeValid: psonoSaasDetails.s_default_token_time_valid,
                    sAllowUserSearchByEmail: psonoSaasDetails.s_allow_user_search_by_email,
                    sAllowUserSearchByUsernamePartial: psonoSaasDetails.s_allow_user_search_by_username_partial,
                    sDisableCentralSecurityReports: psonoSaasDetails.s_disable_central_security_reports,
                    sEnforceMatchingUsernameAndEmail: psonoSaasDetails.s_enforce_matching_username_and_email,
                    sFilesEnabled: psonoSaasDetails.s_files_enabled,
                    sLoggingAudit: psonoSaasDetails.s_logging_audit,
                    sFileserverHandlerEnabled: psonoSaasDetails.s_fileserver_handler_enabled,
                    sMultifactorEnabled: psonoSaasDetails.s_multifactor_enabled,
                    sRecoveryVerifierTimeValid: psonoSaasDetails.s_recovery_verifier_time_valid,
                    sRegistrationEmailFilter: psonoSaasDetails.s_registration_email_filter,
                    instanceState: psonoSaasDetails.state,
                    sLdapgateway: modifiedSLdapgateway,
                    sScimConfigurations: modifiedSScimConfigurations,
                    sSamlConfigurations: modifiedSSamlConfigurations,
                    sOidcConfigurations: modifiedSOidcConfigurations,
                })

            },
            (response) => {
                console.log(response);
            }
        );
        pricingService.getPricing(project.billing_account_id, project.id, "PSONO_SAAS_INSTANCE").then(
            (pricing) => {
                setState({
                    pricing: pricing,
                })
            },
            (response) => {
                console.log(response);
            }
        );

    }, []);


    const calculateEstimatedMonthlyPrice = (variation) => {
        const pricingInfo = state.pricing[variation];
        const hoursPerMonth = (365 * 24) / 12;
        const monthTimeMultiplier = pricingInfo.per === "MINUTE" ? hoursPerMonth * 60 : NaN;
        return Number.parseFloat(pricingInfo.units * pricingInfo.price * monthTimeMultiplier).toFixed(2);
    };

    const onEditCustomDomain = () => {
        history.push("/psono-saas-instance/detail/" + params.id + "/custom-domain/")
    };

    const onLogs = () => {
        history.push("/psono-saas-instance/detail/" + params.id + "/logs/")
    };

    const onEdit = () => {
        setState({
            errors: {},
        })
        const errors = {}

        if (!utils.isValidYaml(state.sLdapgateway)) {
            errors["s_ldapgateway"] = ['INVALID_YAML_FORMAT']
        }
        if (!utils.isValidYaml(state.sScimConfigurations)) {
            errors["s_scim_configurations"] = ['INVALID_YAML_FORMAT']
        }
        if (!utils.isValidYaml(state.sSamlConfigurations)) {
            errors["s_saml_configurations"] = ['INVALID_YAML_FORMAT']
        }
        if (!utils.isValidYaml(state.sOidcConfigurations)) {
            errors["s_oidc_configurations"] = ['INVALID_YAML_FORMAT']
        }
        if (!utils.isValidJson(state.cPortalConfigJson)) {
            errors["c_portal_config_json"] = ['INVALID_JSON_FORMAT']
        }
        if (!utils.isValidJson(state.cWebclientConfigJson)) {
            errors["c_webclient_config_json"] = ['INVALID_JSON_FORMAT']
        }

        if (Object.keys(errors).length !== 0)  { // check if errors array empty
            setState({
                errors: errors,
            })
            return;
        }

        let modifiedSLdapgateway =  converterService.yamlToJson(state.sLdapgateway) || '[]';

        let modifiedSOidcConfigurations = converterService.yamlToJson(state.sOidcConfigurations) || '{}';
        modifiedSOidcConfigurations = JSON.parse(modifiedSOidcConfigurations)
        if (modifiedSOidcConfigurations.hasOwnProperty('OIDC_CONFIGURATIONS')) {
            modifiedSOidcConfigurations = modifiedSOidcConfigurations['OIDC_CONFIGURATIONS'];
        }
        modifiedSOidcConfigurations = JSON.stringify(modifiedSOidcConfigurations);

        let modifiedSScimConfigurations =  converterService.yamlToJson(state.sScimConfigurations) || '{}';
        modifiedSScimConfigurations = JSON.parse(modifiedSScimConfigurations)
        if (modifiedSScimConfigurations.hasOwnProperty('SCIM_CONFIGURATIONS')) {
            modifiedSScimConfigurations = modifiedSScimConfigurations['SCIM_CONFIGURATIONS'];
        }
        modifiedSScimConfigurations = JSON.stringify(modifiedSScimConfigurations);

        let modifiedSSamlConfigurations =  converterService.yamlToJson(state.sSamlConfigurations) || '{}';
        modifiedSSamlConfigurations = JSON.parse(modifiedSSamlConfigurations)
        if (modifiedSSamlConfigurations.hasOwnProperty('SAML_CONFIGURATIONS')) {
            modifiedSSamlConfigurations = modifiedSSamlConfigurations['SAML_CONFIGURATIONS'];
        }
        modifiedSSamlConfigurations = JSON.stringify(modifiedSSamlConfigurations);

        const psonoSaasInstanceId = params.id;

        PsonoSaasInstanceService.updatePsonoSaasInstance(
            psonoSaasInstanceId,
            state.cPortalConfigJson,
            state.cWebclientConfigJson,
            state.name,
            state.sActivationLinkTimeValid,
            state.sAllowLostPassword,
            state.sAllowMultipleSessions,
            state.sAllowRegistration,
            state.sAllowUserSearchByEmail,
            state.sAllowUserSearchByUsernamePartial,
            state.sAllowedDomains,
            state.sAllowedSecondFactors,
            state.sAuthenticationMethods,

            state.sSplunkHost,
            state.sSplunkPort,
            state.sSplunkToken,
            state.sSplunkIndex,
            state.sSplunkProtocol,
            state.sSplunkVerify,
            state.sSplunkSourcetype,

            state.sS3LoggingBucket,
            state.sS3LoggingAccessKeyId,
            state.sS3LoggingSecretAccessKey,

            state.sLogstashTransport,
            state.sLogstashHost,
            state.sLogstashPort,
            state.sLogstashSslEnabled,
            state.sLogstashSslVerify,

            state.sAutoProlongationTokenTimeValid,
            state.sCCentralSecurityReportSecurityRecurrenceInterval,

            state.sCDisableApiKeys,
            state.sCServerSecrets,
            state.sCDisableDeleteAccount,
            state.sCDisableEmergencyCodes,
            state.sCDisableExport,
            state.sCDisableExportOfSharedItems,
            state.sCDisableFileRepositories,
            state.sCDisableLinkShares,
            state.sCDisableRecoveryCodes,
            state.sCEnforce2fa,
            state.sCDisableUnmanagedGroups,
            state.sCEnforceCentralSecurityReports,
            state.sCMinMasterPasswordComplexity,
            state.sCMinMasterPasswordLength,
            state.sCMaxOfflineCacheTimeValid,
            state.sCIPRestrictions.join(','),

            state.sCPasswordGeneratorDefaultPasswordLength,
            state.sCPasswordGeneratorDefaultLettersUppercase,
            state.sCPasswordGeneratorDefaultLettersLowercase,
            state.sCPasswordGeneratorDefaultNumbers,
            state.sCPasswordGeneratorDefaultSpecialChars,

            state.sDefaultTokenTimeValid,
            state.sDisableCentralSecurityReports,
            state.sDisableLastPasswords,
            state.sDuoIntegrationKey,
            state.sDuoSecretKey,
            state.sDuoApiHostname,
            state.sEnforceMatchingUsernameAndEmail,
            state.sFilesEnabled,
            state.sLoggingAudit,
            state.sFileserverHandlerEnabled,
            state.sMaxApiKeyTokenTimeValid,
            state.sMaxAppTokenTimeValid,
            state.sMaxWebclientTokenTimeValid,
            state.sMultifactorEnabled,
            state.sRecoveryVerifierTimeValid,
            state.sRegistrationEmailFilter,
            modifiedSLdapgateway,
            modifiedSScimConfigurations,
            modifiedSSamlConfigurations,
            modifiedSOidcConfigurations,
            state.variation
        ).then(
            (response) => {
                history.push("/psono-saas-instance/");
            },
            (errors) => {
                setState({
                    errors: errors,
                })
            }
        );
    };

    return <>
        {state.instanceState !== "enabled" && <Redirect to="/psono-saas-instance/" />}
        <ContentHeader>
            <Typography component="h1" variant="h5" className={classes.title}>
                <IconButton
                    onClick={() => {
                        history.goBack();
                    }}
                    color="primary"
                    aria-label="go back"
                    className={classes.backButton}
                    size="large">
                    <ArrowBackIcon />
                </IconButton>
                {t("EDIT_PSONO_SAAS_INSTANCE")}
            </Typography>
        </ContentHeader>
        <ContentBody>
            <Grid container>
                <Grid item xs={12} md={8}>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                autoFocus
                                margin="dense" size="small"
                                required
                                id="name"
                                label={t("NAME")}
                                name="name"
                                autoComplete="name"
                                error={state.errors && "name" in state.errors}
                                helperText={state.errors && "name" in state.errors ? t(state.errors["name"][0]) : t("PSONO_SAAS_INSTANCE_NAME_EXPLANATION")}
                                value={state.name}
                                onChange={(event) => {
                                    setState({
                                        name: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                disabled
                                id="slug"
                                label={t("SLUG")}
                                name="slug"
                                autoComplete="slug"
                                error={state.errors && "slug" in state.errors}
                                helperText={state.errors && "slug" in state.errors ? t(state.errors["slug"][0]) : t("PSONO_SAAS_INSTANCE_SLUG_EXPLANATION")}
                                value={state.slug}
                                onChange={(event) => {
                                    setState({
                                        slug: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PsonoSaasRegionSelectField
                                className={classes.textField}
                                project={project}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                disabled
                                id="psono_saas_region_id"
                                error={state.errors && "psono_saas_region_id" in state.errors}
                                helperText={state.errors && "psono_saas_region_id" in state.errors ? t(state.errors["psono_saas_region_id"][0]) : ""}
                                value={state.psonoSaasRegionId}
                                onChange={(psonoSaasRegionId) => {
                                    setState({
                                        psonoSaasRegionId: psonoSaasRegionId
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {Object.keys(state.pricing).length > 0 && (
                                <FormControl
                                    variant="outlined"
                                    className={classes.textField}
                                    margin="dense" size="small"
                                    required
                                    error={state.errors && "variation" in state.errors}
                                >
                                    <InputLabel id="variation-label">{t("MAX_USERS")}</InputLabel>
                                    <Select
                                        labelId="variation-label"
                                        id="variation"
                                        disabled={state.paidTill > new Date()}
                                        value={state.variation}
                                        onChange={(event) => {
                                            setState({
                                                variation: event.target.value
                                            })
                                        }}
                                        label={t("MAX_USERS")}
                                    >
                                        {Object.keys(state.pricing).map((key, index) => {
                                            return (
                                                <MenuItem key={key} value={key}>
                                                    {state.pricing[key].units} {t("USERS")}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {(state.paidTill > new Date() || state.errors && "variation" in state.errors) && (
                                        <FormHelperText>{state.errors && "variation" in state.errors ? t(state.errors["variation"][0]) : (state.paidTill > new Date() ? t("PSONO_SAAS_INSTANCE_PREPAID_EXPLAINED") : "")}</FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="h2" variant="h6" className={classes.h2}>
                                {t("URLS")}
                                <Button
                                    size="small"
                                    style={{marginLeft:'15px'}}
                                    endIcon={<EditIcon style={{width:'15px', height: '15px'}} />}
                                    onClick={onEditCustomDomain}
                                >
                                    {t('CUSTOM_DOMAIN')}
                                </Button>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                disabled
                                id="webclientUrl"
                                label={t("WEBCLIENT_URL")}
                                name="webclientUrl"
                                autoComplete="webclientUrl"
                                error={state.errors && "webclient_url" in state.errors}
                                helperText={state.errors && "webclient_url" in state.errors ? t(state.errors["webclient_url"][0]) : ""}
                                value={state.webclientUrl}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                disabled
                                id="adminPortalUrl"
                                label={t("ADMIN_PORTAL_URL")}
                                name="adminPortalUrl"
                                autoComplete="adminPortalUrl"
                                error={state.errors && "admin_portal_url" in state.errors}
                                helperText={state.errors && "admin_portal_url" in state.errors ? t(state.errors["admin_portal_url"][0]) : ""}
                                value={state.adminPortalUrl}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="h2" variant="h6" className={classes.h2}>
                                {t("DEFAULT_CREDENTIALS")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                disabled
                                id="defaultAdminUsername"
                                label={t("DEFAULT_ADMIN_USERNAME")}
                                name="defaultAdminUsername"
                                autoComplete="defaultAdminUsername"
                                error={state.errors && "default_admin_username" in state.errors}
                                helperText={state.errors && "default_admin_username" in state.errors ? t(state.errors["default_admin_username"][0]) : ""}
                                value={state.defaultAdminUsername}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                disabled
                                id="defaultAdminPassword"
                                label={t("DEFAULT_ADMIN_PASSWORD")}
                                name="defaultAdminPassword"
                                autoComplete="defaultAdminPassword"
                                error={state.errors && "default_admin_password" in state.errors}
                                helperText={state.errors && "default_admin_password" in state.errors ? t(state.errors["default_admin_password"][0]) : ""}
                                value={state.defaultAdminPassword}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="h2" variant="h6" className={classes.h2}>
                                {t("GENERAL_SETTINGS")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                                className={classes.textField}
                                margin="dense" size="small"
                                required
                                error={state.errors && "s_allow_lost_password" in state.errors}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="sAllowLostPassword"
                                            checked={state.sAllowLostPassword}
                                            onChange={(e) => {
                                                setState({
                                                    sAllowLostPassword: e.target.checked
                                                })
                                            }}
                                        />
                                    }
                                    label={t("ALLOW_LOST_PASSWORD")}
                                />
                                <FormHelperText
                                    className={classes.helperText}
                                >
                                    {state.errors && "s_allow_lost_password" in state.errors
                                        ? t(state.errors["s_allow_lost_password"][0])
                                        : t("ALLOW_LOST_PASSWORD_EXPLAINED")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                                className={classes.textField}
                                margin="dense" size="small"
                                required
                                error={state.errors && "s_allow_user_search_by_email" in state.errors}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="sAllowUserSearchByEmail"
                                            checked={state.sAllowUserSearchByEmail}
                                            onChange={(e) => {
                                                setState({
                                                    sAllowUserSearchByEmail: e.target.checked
                                                })
                                            }}
                                        />
                                    }
                                    label={t("ALLOW_USER_SEARCH_BY_EMAIL")}
                                />
                                <FormHelperText
                                    className={classes.helperText}
                                >
                                    {state.errors && "s_allow_user_search_by_email" in state.errors
                                        ? t(state.errors["s_allow_user_search_by_email"][0])
                                        : t("ALLOW_USER_SEARCH_BY_EMAIL_EXPLAINED")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                                className={classes.textField}
                                margin="dense" size="small"
                                required
                                error={state.errors && "s_allow_user_search_by_username_partial" in state.errors}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="sAllowUserSearchByUsernamePartial"
                                            checked={state.sAllowUserSearchByUsernamePartial}
                                            onChange={(e) => {
                                                setState({
                                                    sAllowUserSearchByUsernamePartial: e.target.checked
                                                })
                                            }}
                                        />
                                    }
                                    label={t("ALLOW_USER_SEARCH_BY_USERNAME_PARTIAL")}
                                />
                                <FormHelperText
                                    className={classes.helperText}
                                >
                                    {state.errors && "s_allow_user_search_by_username_partial" in state.errors
                                        ? t(state.errors["s_allow_user_search_by_username_partial"][0])
                                        : t("ALLOW_USER_SEARCH_BY_USERNAME_PARTIAL_EXPLAINED")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>


                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                                className={classes.textField}
                                margin="dense" size="small"
                                required
                                error={state.errors && "s_disable_central_security_reports" in state.errors}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="sDisableCentralSecurityReports"
                                            checked={state.sDisableCentralSecurityReports}
                                            onChange={(e) => {
                                                setState({
                                                    sDisableCentralSecurityReports: e.target.checked
                                                })
                                            }}
                                        />
                                    }
                                    label={t("DISABLE_CENTRAL_SECURITY_REPORTS")}
                                />
                                <FormHelperText
                                    className={classes.helperText}
                                >
                                    {state.errors && "s_disable_central_security_reports" in state.errors
                                        ? t(state.errors["s_disable_central_security_reports"][0])
                                        : t("DISABLE_CENTRAL_SECURITY_REPORTS_EXPLAINED")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                                className={classes.textField}
                                margin="dense" size="small"
                                required
                                error={state.errors && "s_files_enabled" in state.errors}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="sFilesEnabled"
                                            checked={state.sFilesEnabled}
                                            onChange={(e) => {
                                                setState({
                                                    sFilesEnabled: e.target.checked
                                                })
                                            }}
                                        />
                                    }
                                    label={t("FILES_ENABLED")}
                                />
                                <FormHelperText
                                    className={classes.helperText}
                                >
                                    {state.errors && "s_files_enabled" in state.errors
                                        ? t(state.errors["s_files_enabled"][0])
                                        : t("FILES_ENABLED_EXPLAINED")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                                className={classes.textField}
                                margin="dense" size="small"
                                required
                                error={state.errors && "s_fileserver_handler_enabled" in state.errors}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="sFileserverHandlerEnabled"
                                            checked={state.sFileserverHandlerEnabled}
                                            onChange={(e) => {
                                                setState({
                                                    sFileserverHandlerEnabled: e.target.checked
                                                })
                                            }}
                                        />
                                    }
                                    label={t("FILESERVER_HANDLER_ENABLED")}
                                />
                                <FormHelperText
                                    className={classes.helperText}
                                >
                                    {state.errors && "s_fileserver_handler_enabled" in state.errors
                                        ? t(state.errors["s_fileserver_handler_enabled"][0])
                                        : t("FILESERVER_HANDLER_ENABLED_EXPLAINED")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                id="sRecoveryVerifierTimeValid"
                                label={t("RECOVERY_VERIFIER_TIME_VALID")}
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                    },
                                }}
                                name="sRecoveryVerifierTimeValid"
                                autoComplete="sRecoveryVerifierTimeValid"
                                error={state.errors && "s_recovery_verifier_time_valid" in state.errors}
                                helperText={state.errors && "s_recovery_verifier_time_valid" in state.errors ? t(state.errors["s_recovery_verifier_time_valid"][0]) : t("RECOVERY_VERIFIER_TIME_VALID_EXPLAINED")}
                                value={state.sRecoveryVerifierTimeValid}
                                onChange={(event) => {
                                    setState({
                                        sRecoveryVerifierTimeValid: event.target.value
                                    })
                                }}
                            />
                        </Grid>



                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                id="sAutoProlongationTokenTimeValid"
                                label={t("AUTO_PROLONGATION_TOKEN_TIME_VALID")}
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                    },
                                }}
                                name="sAutoProlongationTokenTimeValid"
                                autoComplete="sAutoProlongationTokenTimeValid"
                                error={state.errors && "s_auto_prolongation_token_time_valid" in state.errors}
                                helperText={
                                    state.errors && "s_auto_prolongation_token_time_valid" in state.errors
                                        ? t(state.errors["s_auto_prolongation_token_time_valid"][0])
                                        : t("AUTO_PROLONGATION_TOKEN_TIME_VALID_EXPLAINED")
                                }
                                value={state.sAutoProlongationTokenTimeValid}
                                onChange={(event) => {
                                    setState({
                                        sAutoProlongationTokenTimeValid: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                id="sDefaultTokenTimeValid"
                                label={t("DEFAULT_TOKEN_TIME_VALID")}
                                type="number"
                                name="sDefaultTokenTimeValid"
                                autoComplete="sDefaultTokenTimeValid"
                                error={state.errors && "s_default_token_time_valid" in state.errors}
                                helperText={state.errors && "s_default_token_time_valid" in state.errors ? t(state.errors["s_default_token_time_valid"][0]) : t("DEFAULT_TOKEN_TIME_VALID_EXPLAINED")}
                                value={state.sDefaultTokenTimeValid}
                                onChange={(event) => {
                                    setState({
                                        sDefaultTokenTimeValid: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                id="sMaxAppTokenTimeValid"
                                label={t("MAX_APP_TOKEN_TIME_VALID")}
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                    },
                                }}
                                name="sMaxAppTokenTimeValid"
                                autoComplete="sMaxAppTokenTimeValid"
                                error={state.errors && "s_max_app_token_time_valid" in state.errors}
                                helperText={state.errors && "s_max_app_token_time_valid" in state.errors ? t(state.errors["s_max_app_token_time_valid"][0]) : t("MAX_APP_TOKEN_TIME_VALID_EXPLAINED")}
                                value={state.sMaxAppTokenTimeValid}
                                onChange={(event) => {
                                    setState({
                                        sMaxAppTokenTimeValid: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                id="sMaxWebclientTokenTimeValid"
                                label={t("MAX_WEBCLIENT_TOKEN_TIME_VALID")}
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                    },
                                }}
                                name="sMaxWebclientTokenTimeValid"
                                autoComplete="sMaxWebclientTokenTimeValid"
                                error={state.errors && "s_max_webclient_token_time_valid" in state.errors}
                                helperText={
                                    state.errors && "s_max_webclient_token_time_valid" in state.errors ? t(state.errors["s_max_webclient_token_time_valid"][0]) : t("MAX_WEBCLIENT_TOKEN_TIME_VALID_EXPLAINED")
                                }
                                value={state.sMaxWebclientTokenTimeValid}
                                onChange={(event) => {
                                    setState({
                                        sMaxWebclientTokenTimeValid: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                id="sMaxApiKeyTokenTimeValid"
                                label={t("MAX_API_KEY_TOKEN_TIME_VALID")}
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                    },
                                }}
                                name="sMaxApiKeyTokenTimeValid"
                                autoComplete="sMaxApiKeyTokenTimeValid"
                                error={state.errors && "s_max_api_key_token_time_valid" in state.errors}
                                helperText={state.errors && "s_max_api_key_token_time_valid" in state.errors ? t(state.errors["s_max_api_key_token_time_valid"][0]) : t("MAX_API_KEY_TOKEN_TIME_VALID_EXPLAINED")}
                                value={state.sMaxApiKeyTokenTimeValid}
                                onChange={(event) => {
                                    setState({
                                        sMaxApiKeyTokenTimeValid: event.target.value
                                    })
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography component="h2" variant="h6" className={classes.h2}>
                                {t("COMPLIANCE_SETTINGS")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                                className={classes.textField}
                                margin="dense" size="small"
                                required
                                error={state.errors && "s_allow_multiple_sessions" in state.errors}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="sAllowMultipleSessions"
                                            checked={state.sAllowMultipleSessions}
                                            onChange={(e) => {
                                                setState({
                                                    sAllowMultipleSessions: e.target.checked
                                                })
                                            }}
                                        />
                                    }
                                    label={t("ALLOW_MULTIPLE_SESSIONS")}
                                />
                                <FormHelperText
                                    className={classes.helperText}
                                >
                                    {state.errors && "s_allow_multiple_sessions" in state.errors
                                        ? t(state.errors["s_allow_multiple_sessions"][0])
                                        : t("ALLOW_MULTIPLE_SESSIONS_EXPLAINED")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                                className={classes.textField}
                                margin="dense" size="small"
                                required
                                error={state.errors && "s_c_disable_api_keys" in state.errors}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="sCDisableApiKeys"
                                            checked={state.sCDisableApiKeys}
                                            onChange={(e) => {
                                                setState({
                                                    sCDisableApiKeys: e.target.checked
                                                })
                                            }}
                                        />
                                    }
                                    label={t("COMPLIANCE_DISABLE_API_KEYS")}
                                />
                                <FormHelperText
                                    className={classes.helperText}
                                >
                                    {state.errors && "s_c_disable_api_keys" in state.errors
                                        ? t(state.errors["s_c_disable_api_keys"][0])
                                        : t("COMPLIANCE_DISABLE_API_KEYS_EXPLAINED")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                                className={classes.textField}
                                margin="dense" size="small"
                                required
                                error={state.errors && "s_c_disable_delete_account" in state.errors}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="sCDisableDeleteAccount"
                                            checked={state.sCDisableDeleteAccount}
                                            onChange={(e) => {
                                                setState({
                                                    sCDisableDeleteAccount: e.target.checked
                                                })
                                            }}
                                        />
                                    }
                                    label={t("COMPLIANCE_DISABLE_DELETE_ACCOUNT")}
                                />
                                <FormHelperText
                                    className={classes.helperText}
                                >
                                    {state.errors && "s_c_disable_delete_account" in state.errors
                                        ? t(state.errors["s_c_disable_delete_account"][0])
                                        : t("COMPLIANCE_DISABLE_DELETE_ACCOUNT_EXPLAINED")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                                className={classes.textField}
                                margin="dense" size="small"
                                required
                                error={state.errors && "s_c_disable_emergency_codes" in state.errors}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="sCDisableEmergencyCodes"
                                            checked={state.sCDisableEmergencyCodes}
                                            onChange={(e) => {
                                                setState({
                                                    sCDisableEmergencyCodes: e.target.checked
                                                })
                                            }}
                                        />
                                    }
                                    label={t("COMPLIANCE_DISABLE_EMERGENCY_CODES")}
                                />
                                <FormHelperText
                                    className={classes.helperText}
                                >
                                    {state.errors && "s_c_disable_emergency_codes" in state.errors
                                        ? t(state.errors["s_c_disable_emergency_codes"][0])
                                        : t("COMPLIANCE_DISABLE_EMERGENCY_CODES_EXPLAINED")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                                className={classes.textField}
                                margin="dense" size="small"
                                required
                                error={state.errors && "s_c_disable_export" in state.errors}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="sCDisableExport"
                                            checked={state.sCDisableExport}
                                            onChange={(e) => {
                                                setState({
                                                    sCDisableExport: e.target.checked
                                                })
                                            }}
                                        />
                                    }
                                    label={t("COMPLIANCE_DISABLE_EXPORT")}
                                />
                                <FormHelperText
                                    className={classes.helperText}
                                >
                                    {state.errors && "s_c_disable_export" in state.errors
                                        ? t(state.errors["s_c_disable_export"][0])
                                        : t("COMPLIANCE_DISABLE_EXPORT_EXPLAINED")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                                className={classes.textField}
                                margin="dense" size="small"
                                required
                                error={state.errors && "s_c_disable_export_of_shared_items" in state.errors}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="sCDisableExportOfSharedItems"
                                            checked={state.sCDisableExportOfSharedItems}
                                            onChange={(e) => {
                                                setState({
                                                    sCDisableExportOfSharedItems: e.target.checked
                                                })
                                            }}
                                        />
                                    }
                                    label={t("COMPLIANCE_DISABLE_EXPORT_OF_SHARED_ITEMS")}
                                />
                                <FormHelperText
                                    className={classes.helperText}
                                >
                                    {state.errors && "s_c_disable_export_of_shared_items" in state.errors
                                        ? t(state.errors["s_c_disable_export_of_shared_items"][0])
                                        : t("COMPLIANCE_DISABLE_EXPORT_OF_SHARED_ITEMS_EXPLAINED")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                                className={classes.textField}
                                margin="dense" size="small"
                                required
                                error={state.errors && "s_c_disable_unmanaged_groups" in state.errors}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="sCDisableUnmanagedGroups"
                                            checked={state.sCDisableUnmanagedGroups}
                                            onChange={(e) => {
                                                setState({
                                                    sCDisableUnmanagedGroups: e.target.checked
                                                })
                                            }}
                                        />
                                    }
                                    label={t("COMPLIANCE_DISABLE_UNMANAGED_GROUPS")}
                                />
                                <FormHelperText
                                    className={classes.helperText}
                                >
                                    {state.errors && "s_c_disable_unmanaged_groups" in state.errors
                                        ? t(state.errors["s_c_disable_unmanaged_groups"][0])
                                        : t("COMPLIANCE_DISABLE_UNMANAGED_GROUPS_EXPLAINED")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                                className={classes.textField}
                                margin="dense" size="small"
                                required
                                error={state.errors && "s_c_disable_file_repositories" in state.errors}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="sCDisableFileRepositories"
                                            checked={state.sCDisableFileRepositories}
                                            onChange={(e) => {
                                                setState({
                                                    sCDisableFileRepositories: e.target.checked
                                                })
                                            }}
                                        />
                                    }
                                    label={t("COMPLIANCE_DISABLE_FILE_REPOSITORIES")}
                                />
                                <FormHelperText
                                    className={classes.helperText}
                                >
                                    {state.errors && "s_c_disable_file_repositories" in state.errors
                                        ? t(state.errors["s_c_disable_file_repositories"][0])
                                        : t("COMPLIANCE_DISABLE_FILE_REPOSITORIES_EXPLAINED")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                                className={classes.textField}
                                margin="dense" size="small"
                                required
                                error={state.errors && "s_c_disable_link_shares" in state.errors}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="sCDisableLinkShares"
                                            checked={state.sCDisableLinkShares}
                                            onChange={(e) => {
                                                setState({
                                                    sCDisableLinkShares: e.target.checked
                                                })
                                            }}
                                        />
                                    }
                                    label={t("COMPLIANCE_DISABLE_LINK_SHARES")}
                                />
                                <FormHelperText
                                    className={classes.helperText}
                                >
                                    {state.errors && "s_c_disable_link_shares" in state.errors
                                        ? t(state.errors["s_c_disable_link_shares"][0])
                                        : t("COMPLIANCE_DISABLE_LINK_SHARES_EXPLAINED")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                                className={classes.textField}
                                margin="dense" size="small"
                                required
                                error={state.errors && "s_c_disable_recovery_codes" in state.errors}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="sCDisableRecoveryCodes"
                                            checked={state.sCDisableRecoveryCodes}
                                            onChange={(e) => {
                                                setState({
                                                    sCDisableRecoveryCodes: e.target.checked
                                                })
                                            }}
                                        />
                                    }
                                    label={t("COMPLIANCE_DISABLE_RECOVERY_CODES")}
                                />
                                <FormHelperText
                                    className={classes.helperText}
                                >
                                    {state.errors && "s_c_disable_recovery_codes" in state.errors
                                        ? t(state.errors["s_c_disable_recovery_codes"][0])
                                        : t("COMPLIANCE_DISABLE_RECOVERY_CODES_EXPLAINED")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                                className={classes.textField}
                                margin="dense" size="small"
                                required
                                error={state.errors && "s_c_enforce_central_security_reports" in state.errors}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="sCEnforceCentralSecurityReports"
                                            checked={state.sCEnforceCentralSecurityReports}
                                            onChange={(e) => {
                                                setState({
                                                    sCEnforceCentralSecurityReports: e.target.checked
                                                })
                                            }}
                                        />
                                    }
                                    label={t("COMPLIANCE_ENFORCE_CENTRAL_SECURITY_REPORTS")}
                                />
                                <FormHelperText
                                    className={classes.helperText}
                                >
                                    {state.errors && "s_c_enforce_central_security_reports" in state.errors
                                        ? t(state.errors["s_c_enforce_central_security_reports"][0])
                                        : t("COMPLIANCE_ENFORCE_CENTRAL_SECURITY_REPORTS_EXPLAINED")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                id="sCCentralSecurityReportSecurityRecurrenceInterval"
                                label={t("COMPLIANCE_CENTRAL_SECURITY_REPORT_SECURITY_RECURRENCE_INTERVAL")}
                                type="number"
                                name="sCCentralSecurityReportSecurityRecurrenceInterval"
                                autoComplete="sCCentralSecurityReportSecurityRecurrenceInterval"
                                error={state.errors && "s_c_central_security_report_security_recurrence_interval" in state.errors}
                                helperText={
                                    state.errors && "s_c_central_security_report_security_recurrence_interval" in state.errors
                                        ? t(state.errors["s_c_central_security_report_security_recurrence_interval"][0])
                                        : t("COMPLIANCE_CENTRAL_SECURITY_REPORT_SECURITY_RECURRENCE_INTERVAL_EXPLAINED")
                                }
                                value={state.sCCentralSecurityReportSecurityRecurrenceInterval}
                                onChange={(event) => {
                                    setState({
                                        sCCentralSecurityReportSecurityRecurrenceInterval: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl
                                    variant="outlined"
                                    className={classes.textField}
                                    margin="dense" size="small"
                                    required
                                    error={state.errors && "sCServerSecrets" in state.errors}
                                >
                                    <InputLabel id="sCServerSecrets-label">{t("COMPLIANCE_SERVER_SECRETS")}</InputLabel>
                                    <Select
                                        labelId="sCServerSecrets-label"
                                        id="sCServerSecrets"
                                        value={state.sCServerSecrets}
                                        onChange={(event) => {
                                            setState({
                                                sCServerSecrets: event.target.value
                                            })
                                        }}
                                        label={t("COMPLIANCE_SERVER_SECRETS")}
                                    >
                                        <MenuItem key={'auto'} value={'auto'}>
                                            auto
                                        </MenuItem>
                                        <MenuItem key={'all'} value={'all'}>
                                            all
                                        </MenuItem>
                                        <MenuItem key={'noone'} value={'noone'}>
                                            noone
                                        </MenuItem>
                                    </Select>
                                    <FormHelperText>{state.errors && "sCServerSecrets" in state.errors ? t(state.errors["sCServerSecrets"][0]) : t("COMPLIANCE_SERVER_SECRETS_EXPLAINED")}</FormHelperText>
                                </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                id="sCMaxOfflineCacheTimeValid"
                                label={t("COMPLIANCE_MAX_OFFLINE_CACHE_TIME_VALID")}
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        max: 31536000,
                                        min: 0,
                                    },
                                }}
                                name="sCMaxOfflineCacheTimeValid"
                                autoComplete="sCMaxOfflineCacheTimeValid"
                                error={state.errors && "s_c_max_offline_cache_time_valid" in state.errors}
                                helperText={
                                    state.errors && "s_c_max_offline_cache_time_valid" in state.errors ? t(state.errors["s_c_max_offline_cache_time_valid"][0]) : t("COMPLIANCE_MAX_OFFLINE_CACHE_TIME_VALID_EXPLAINED")
                                }
                                value={state.sCMaxOfflineCacheTimeValid}
                                onChange={(event) => {
                                    setState({
                                        sCMaxOfflineCacheTimeValid: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                multiline
                                minRows={3}
                                id="sCIPRestrictions"
                                label={t("COMPLIANCE_IP_RESTRICTIONS")}
                                name="sCIPRestrictions"
                                autoComplete="sCIPRestrictions"
                                error={state.errors && "s_c_ip_restrictions" in state.errors}
                                helperText={
                                    state.errors && "s_c_ip_restrictions" in state.errors
                                        ? t(state.errors["s_c_ip_restrictions"][0])
                                        : t("COMPLIANCE_IP_RESTRICTIONS_EXPLAINED")
                                }
                                value={state.sCIPRestrictions.join('\n')}
                                onChange={(event) => {
                                    setState({
                                        sCIPRestrictions: event.target.value.split(
                                                                        /[\s,;]+/
                                                                    )
                                    })
                                }}
                            />
                        </Grid>


                        <Grid item xs={12}>
                            <Typography component="h2" variant="h6" className={classes.h2}>
                                {t("AUTHENTICATION")}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                id="sAuthenticationMethods"
                                label={t("AUTHENTICATION_METHODS")}
                                name="sAuthenticationMethods"
                                autoComplete="sAuthenticationMethods"
                                error={state.errors && "s_authentication_methods" in state.errors}
                                helperText={
                                    state.errors && "s_authentication_methods" in state.errors
                                        ? t(state.errors["s_authentication_methods"][0])
                                        : t("AUTHENTICATION_METHODS_EXPLAINED")
                                }
                                value={state.sAuthenticationMethods}
                                onChange={(event) => {
                                    setState({
                                        sAuthenticationMethods: event.target.value
                                    })
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                multiline
                                minRows={3}
                                id="sLdapgateway"
                                label={t("LDAPGATEWAY")}
                                name="sLdapgateway"
                                autoComplete="sLdapgateway"
                                error={state.errors && "s_ldapgateway" in state.errors}
                                helperText={state.errors && "s_ldapgateway" in state.errors ? t(state.errors["s_ldapgateway"][0]) : t("LDAPGATEWAY_EXPLAINED")}
                                value={state.sLdapgateway}
                                onChange={(event) => {
                                    setState({
                                        sLdapgateway: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                multiline
                                minRows={3}
                                id="sScimConfigurations"
                                label={t("SCIM_CONFIGURATIONS")}
                                name="sScimConfigurations"
                                autoComplete="sScimConfigurations"
                                error={state.errors && "s_scim_configurations" in state.errors}
                                helperText={
                                    state.errors && "s_scim_configurations" in state.errors
                                        ? t(state.errors["s_scim_configurations"][0])
                                        : t("SCIM_CONFIGURATIONS_EXPLAINED")
                                }
                                value={state.sScimConfigurations}
                                onChange={(event) => {
                                    setState({
                                        sScimConfigurations: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                multiline
                                minRows={3}
                                id="sSamlConfigurations"
                                label={t("SAML_CONFIGURATIONS")}
                                name="sSamlConfigurations"
                                autoComplete="sSamlConfigurations"
                                error={state.errors && "s_saml_configurations" in state.errors}
                                helperText={
                                    state.errors && "s_saml_configurations" in state.errors
                                        ? t(state.errors["s_saml_configurations"][0])
                                        : t("SAML_CONFIGURATIONS_EXPLAINED")
                                }
                                value={state.sSamlConfigurations}
                                onChange={(event) => {
                                    setState({
                                        sSamlConfigurations: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                multiline
                                minRows={3}
                                id="sOidcConfigurations"
                                label={t("OIDC_CONFIGURATIONS")}
                                name="sOidcConfigurations"
                                autoComplete="sOidcConfigurations"
                                error={state.errors && "s_oidc_configurations" in state.errors}
                                helperText={
                                    state.errors && "s_oidc_configurations" in state.errors
                                        ? t(state.errors["s_oidc_configurations"][0])
                                        : t("OIDC_CONFIGURATIONS_EXPLAINED")
                                }
                                value={state.sOidcConfigurations}
                                onChange={(event) => {
                                    setState({
                                        sOidcConfigurations: event.target.value
                                    })
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography component="h2" variant="h6" className={classes.h2}>
                                {t("REGISTRATION")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                                className={classes.textField}
                                margin="dense" size="small"
                                required
                                error={state.errors && "s_allow_registration" in state.errors}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="sAllowRegistration"
                                            checked={state.sAllowRegistration}
                                            onChange={(e) => {
                                                setState({
                                                    sAllowRegistration: e.target.checked
                                                })
                                            }}
                                        />
                                    }
                                    label={t("ALLOW_REGISTRATION")}
                                />
                                <FormHelperText
                                    className={classes.helperText}
                                >
                                    {state.errors && "s_allow_registration" in state.errors
                                        ? t(state.errors["s_allow_registration"][0])
                                        : t("ALLOW_REGISTRATION_EXPLAINED")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        {state.sAllowRegistration && (
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        className={classes.textField}
                                        variant="outlined"
                                        margin="dense" size="small"
                                        required
                                        id="sAllowedDomains"
                                        label={t("ALLOWED_DOMAINS")}
                                        name="sAllowedDomains"
                                        autoComplete="sAllowedDomains"
                                        error={state.errors && "s_allowed_domains" in state.errors}
                                        helperText={state.errors && "s_allowed_domains" in state.errors ? t(state.errors["s_allowed_domains"][0]) : t("ALLOWED_DOMAINS_EXPLAINED")}
                                        value={state.sAllowedDomains}
                                        onChange={(event) => {
                                            setState({
                                                sAllowedDomains: event.target.value
                                            })
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        className={classes.textField}
                                        variant="outlined"
                                        margin="dense" size="small"
                                        id="sRegistrationEmailFilter"
                                        label={t("REGISTRATION_EMAIL_FILTER")}
                                        name="sRegistrationEmailFilter"
                                        autoComplete="sRegistrationEmailFilter"
                                        error={state.errors && "s_registration_email_filter" in state.errors}
                                        helperText={
                                            state.errors && "s_registration_email_filter" in state.errors
                                                ? t(state.errors["s_registration_email_filter"][0])
                                                : t("REGISTRATION_EMAIL_FILTER_EXPLAINED")
                                        }
                                        value={state.sRegistrationEmailFilter}
                                        onChange={(event) => {
                                            setState({
                                                sRegistrationEmailFilter: event.target.value
                                            })
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl
                                        variant="outlined"
                                        className={classes.textField}
                                        margin="dense" size="small"
                                        required
                                        error={state.errors && "s_enforce_matching_username_and_email" in state.errors}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="sEnforceMatchingUsernameAndEmail"
                                                    checked={state.sEnforceMatchingUsernameAndEmail}
                                                    onChange={(e) => {
                                                        setState({
                                                            sEnforceMatchingUsernameAndEmail: e.target.checked
                                                        })
                                                    }}
                                                />
                                            }
                                            label={t("ENFORCE_MATCHING_USERNAME_AND_EMAIL")}
                                        />
                                        <FormHelperText
                                            className={classes.helperText}
                                        >
                                            {state.errors && "s_enforce_matching_username_and_email" in state.errors
                                                ? t(state.errors["s_enforce_matching_username_and_email"][0])
                                                : t("ENFORCE_MATCHING_USERNAME_AND_EMAIL_EXPLAINED")}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        className={classes.textField}
                                        variant="outlined"
                                        margin="dense" size="small"
                                        required
                                        id="sActivationLinkTimeValid"
                                        label={t("ACTIVATION_LINK_TIME_VALID")}
                                        type="number"
                                        InputProps={{
                                            inputProps: {
                                                min: 0,
                                            },
                                        }}
                                        name="sActivationLinkTimeValid"
                                        autoComplete="sActivationLinkTimeValid"
                                        error={state.errors && "s_activation_link_time_valid" in state.errors}
                                        helperText={state.errors && "s_activation_link_time_valid" in state.errors ? t(state.errors["s_activation_link_time_valid"][0]) : t("ACTIVATION_LINK_TIME_VALID_EXPLAINED")}
                                        value={state.sActivationLinkTimeValid}
                                        onChange={(event) => {
                                            setState({
                                                sActivationLinkTimeValid: event.target.value
                                            })
                                        }}
                                    />
                                </Grid>
                            </>
                        )}

                        <Grid item xs={12}>
                            <Typography component="h2" variant="h6" className={classes.h2}>
                                {t("MASTER_PASSWORD")}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                id="sCMinMasterPasswordComplexity"
                                label={t("COMPLIANCE_MIN_MASTER_PASSWORD_COMPLEXITY")}
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        max: 4,
                                        min: 0,
                                    },
                                }}
                                name="sCMinMasterPasswordComplexity"
                                autoComplete="sCMinMasterPasswordComplexity"
                                error={state.errors && "s_c_min_master_password_complexity" in state.errors}
                                helperText={
                                    state.errors && "s_c_min_master_password_complexity" in state.errors ? t(state.errors["s_c_min_master_password_complexity"][0]) : t("COMPLIANCE_MIN_MASTER_PASSWORD_COMPLEXITY_EXPLAINED")
                                }
                                value={state.sCMinMasterPasswordComplexity}
                                onChange={(event) => {
                                    setState({
                                        sCMinMasterPasswordComplexity: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                id="sCMinMasterPasswordLength"
                                label={t("COMPLIANCE_MIN_MASTER_PASSWORD_LENGTH")}
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        max: 99,
                                        min: 0,
                                    },
                                }}
                                name="sCMinMasterPasswordLength"
                                autoComplete="sCMinMasterPasswordLength"
                                error={state.errors && "s_c_min_master_password_length" in state.errors}
                                helperText={state.errors && "s_c_min_master_password_length" in state.errors ? t(state.errors["s_c_min_master_password_length"][0]) : t("COMPLIANCE_MIN_MASTER_PASSWORD_LENGTH_EXPLAINED")}
                                value={state.sCMinMasterPasswordLength}
                                onChange={(event) => {
                                    setState({
                                        sCMinMasterPasswordLength: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                id="sDisableLastPasswords"
                                label={t("DISABLE_LAST_PASSWORDS")}
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        min: 0,
                                    },
                                }}
                                name="sDisableLastPasswords"
                                autoComplete="sDisableLastPasswords"
                                error={state.errors && "s_disable_last_passwords" in state.errors}
                                helperText={state.errors && "s_disable_last_passwords" in state.errors ? t(state.errors["s_disable_last_passwords"][0]) : t("DISABLE_LAST_PASSWORDS_EXPLAINED")}
                                value={state.sDisableLastPasswords}
                                onChange={(event) => {
                                    setState({
                                        sDisableLastPasswords: event.target.value
                                    })
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography component="h2" variant="h6" className={classes.h2}>
                                {t("TWO_FACTOR_SETTINGS")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                id="sAllowedSecondFactors"
                                label={t("ALLOWED_SECOND_FACTORS")}
                                name="sAllowedSecondFactors"
                                autoComplete="sAllowedSecondFactors"
                                error={state.errors && "s_allowed_second_factors" in state.errors}
                                helperText={
                                    state.errors && "s_allowed_second_factors" in state.errors
                                        ? t(state.errors["s_allowed_second_factors"][0])
                                        : t("ALLOWED_SECOND_FACTORS_EXPLAINED")
                                }
                                value={state.sAllowedSecondFactors}
                                onChange={(event) => {
                                    setState({
                                        sAllowedSecondFactors: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                                className={classes.textField}
                                margin="dense" size="small"
                                required
                                error={state.errors && "s_c_enforce_2fa" in state.errors}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="sCEnforce2fa"
                                            checked={state.sCEnforce2fa}
                                            onChange={(e) => {
                                                setState({
                                                    sCEnforce2fa: e.target.checked
                                                })
                                            }}
                                        />
                                    }
                                    label={t("COMPLIANCE_ENFORCE_2FA")}
                                />
                                <FormHelperText
                                    className={classes.helperText}
                                >
                                    {state.errors && "s_c_enforce_2fa" in state.errors ? t(state.errors["s_c_enforce_2fa"][0]) : t("COMPLIANCE_ENFORCE_2FA_EXPLAINED")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                                className={classes.textField}
                                margin="dense" size="small"
                                required
                                error={state.errors && "s_multifactor_enabled" in state.errors}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="sMultifactorEnabled"
                                            checked={state.sMultifactorEnabled}
                                            onChange={(e) => {
                                                setState({
                                                    sMultifactorEnabled: e.target.checked
                                                })
                                            }}
                                        />
                                    }
                                    label={t("MULTIFACTOR_ENABLED")}
                                />
                                <FormHelperText
                                    className={classes.helperText}
                                >
                                    {state.errors && "s_multifactor_enabled" in state.errors
                                        ? t(state.errors["s_multifactor_enabled"][0])
                                        : t("MULTIFACTOR_ENABLED_EXPLAINED")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography component="h2" variant="h6" className={classes.h2}>
                                {t("PASSWORD_GENERATOR")}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                id="sCPasswordGeneratorDefaultPasswordLength"
                                label={t("COMPLIANCE_PASSWORD_GENERATOR_DEFAULT_PASSWORD_LENGTH")}
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        max: 99,
                                        min: 0,
                                    },
                                }}
                                name="sCPasswordGeneratorDefaultPasswordLength"
                                autoComplete="sCPasswordGeneratorDefaultPasswordLength"
                                error={state.errors && "s_c_password_generator_default_password_length" in state.errors}
                                helperText={
                                    state.errors && "s_c_password_generator_default_password_length" in state.errors
                                        ? t(state.errors["s_c_password_generator_default_password_length"][0])
                                        : t("COMPLIANCE_PASSWORD_GENERATOR_DEFAULT_PASSWORD_LENGTH_EXPLAINED")
                                }
                                value={state.sCPasswordGeneratorDefaultPasswordLength}
                                onChange={(event) => {
                                    setState({
                                        sCPasswordGeneratorDefaultPasswordLength: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                id="sCPasswordGeneratorDefaultLettersUppercase"
                                label={t("COMPLIANCE_PASSWORD_GENERATOR_DEFAULT_LETTERS_UPPERCASE")}
                                name="sCPasswordGeneratorDefaultLettersUppercase"
                                autoComplete="sCPasswordGeneratorDefaultLettersUppercase"
                                error={state.errors && "s_c_password_generator_default_letters_uppercase" in state.errors}
                                helperText={
                                    state.errors && "s_c_password_generator_default_letters_uppercase" in state.errors
                                        ? t(state.errors["s_c_password_generator_default_letters_uppercase"][0])
                                        : t("COMPLIANCE_PASSWORD_GENERATOR_DEFAULT_LETTERS_UPPERCASE_EXPLAINED")
                                }
                                value={state.sCPasswordGeneratorDefaultLettersUppercase}
                                onChange={(event) => {
                                    setState({
                                        sCPasswordGeneratorDefaultLettersUppercase: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                id="sCPasswordGeneratorDefaultLettersLowercase"
                                label={t("COMPLIANCE_PASSWORD_GENERATOR_DEFAULT_LETTERS_LOWERCASE")}
                                name="sCPasswordGeneratorDefaultLettersLowercase"
                                autoComplete="sCPasswordGeneratorDefaultLettersLowercase"
                                error={state.errors && "s_c_password_generator_default_letters_lowercase" in state.errors}
                                helperText={
                                    state.errors && "s_c_password_generator_default_letters_lowercase" in state.errors
                                        ? t(state.errors["s_c_password_generator_default_letters_lowercase"][0])
                                        : t("COMPLIANCE_PASSWORD_GENERATOR_DEFAULT_LETTERS_LOWERCASE_EXPLAINED")
                                }
                                value={state.sCPasswordGeneratorDefaultLettersLowercase}
                                onChange={(event) => {
                                    setState({
                                        sCPasswordGeneratorDefaultLettersLowercase: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                id="sCPasswordGeneratorDefaultNumbers"
                                label={t("COMPLIANCE_PASSWORD_GENERATOR_DEFAULT_NUMBERS")}
                                name="sCPasswordGeneratorDefaultNumbers"
                                autoComplete="sCPasswordGeneratorDefaultNumbers"
                                error={state.errors && "s_c_password_generator_default_numbers" in state.errors}
                                helperText={
                                    state.errors && "s_c_password_generator_default_numbers" in state.errors
                                        ? t(state.errors["s_c_password_generator_default_numbers"][0])
                                        : t("COMPLIANCE_PASSWORD_GENERATOR_DEFAULT_NUMBERS_EXPLAINED")
                                }
                                value={state.sCPasswordGeneratorDefaultNumbers}
                                onChange={(event) => {
                                    setState({
                                        sCPasswordGeneratorDefaultNumbers: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                id="sCPasswordGeneratorDefaultSpecialChars"
                                label={t("COMPLIANCE_PASSWORD_GENERATOR_DEFAULT_SPECIAL_CHARS")}
                                name="sCPasswordGeneratorDefaultSpecialChars"
                                autoComplete="sCPasswordGeneratorDefaultSpecialChars"
                                error={state.errors && "s_c_password_generator_default_special_chars" in state.errors}
                                helperText={
                                    state.errors && "s_c_password_generator_default_special_chars" in state.errors
                                        ? t(state.errors["s_c_password_generator_default_special_chars"][0])
                                        : t("COMPLIANCE_PASSWORD_GENERATOR_DEFAULT_SPECIAL_CHARS_EXPLAINED")
                                }
                                value={state.sCPasswordGeneratorDefaultSpecialChars}
                                onChange={(event) => {
                                    setState({
                                        sCPasswordGeneratorDefaultSpecialChars: event.target.value
                                    })
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography component="h2" variant="h6" className={classes.h2}>
                                {t("DUO_SETTINGS")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                id="sDuoIntegrationKey"
                                label="Integration key"
                                name="sDuoIntegrationKey"
                                autoComplete="sDuoIntegrationKey"
                                error={state.errors && "s_duo_integration_key" in state.errors}
                                helperText={state.errors && "s_duo_integration_key" in state.errors ? t(state.errors["s_duo_integration_key"][0]) : t("DUO_INTEGRATION_KEY_EXPLAINED")}
                                value={state.sDuoIntegrationKey}
                                onChange={(event) => {
                                    setState({
                                        sDuoIntegrationKey: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                id="sDuoSecretKey"
                                label="Secret key"
                                name="sDuoSecretKey"
                                autoComplete="sDuoSecretKey"
                                error={state.errors && "s_duo_secret_key" in state.errors}
                                helperText={state.errors && "s_duo_secret_key" in state.errors ? t(state.errors["s_duo_secret_key"][0]) : t("DUO_SECRET_KEY_EXPLAINED")}
                                value={state.sDuoSecretKey}
                                onChange={(event) => {
                                    setState({
                                        sDuoSecretKey: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                id="sDuoApiHostname"
                                label="API hostname"
                                name="sDuoApiHostname"
                                autoComplete="sDuoApiHostname"
                                error={state.errors && "s_duo_api_hostname" in state.errors}
                                helperText={state.errors && "s_duo_api_hostname" in state.errors ? t(state.errors["s_duo_api_hostname"][0]) : t("DUO_API_HOSTNAME_EXPLAINED")}
                                value={state.sDuoApiHostname}
                                onChange={(event) => {
                                    setState({
                                        sDuoApiHostname: event.target.value
                                    })
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography component="h2" variant="h6" className={classes.h2}>
                                {t("AUDIT")}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                                className={classes.textField}
                                margin="dense" size="small"
                                required
                                error={state.errors && "s_logging_audit" in state.errors}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="sLoggingAudit"
                                            checked={state.sLoggingAudit}
                                            onChange={(e) => {
                                                setState({
                                                    sLoggingAudit: e.target.checked
                                                })
                                            }}
                                        />
                                    }
                                    label={t("LOGGING_AUDIT")}
                                />
                                <FormHelperText
                                    className={classes.helperText}
                                >
                                    {state.errors && "s_logging_audit" in state.errors
                                        ? t(state.errors["s_logging_audit"][0])
                                        : t("LOGGING_AUDIT_EXPLAINED")}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        {state.sLoggingAudit && <Grid item xs={12}>
                            <AppBar elevation={0} position="static" color="default">
                                <Tabs value={auditLoggingTab} onChange={(event, newValue) => {
                                    setAuditLoggingTab(newValue);
                                }} aria-label="audit logging">
                                    <Tab label={t("SPLUNK_SETTINGS")}/>
                                    <Tab label={t("S3_SETTINGS")}/>
                                    <Tab label={t("LOGSTASH_SETTINGS")}/>
                                    <Button
                                        size="small"
                                        style={{marginLeft:'15px'}}
                                        endIcon={<ListAltIcon style={{width:'15px', height: '15px'}} />}
                                        onClick={onLogs}
                                    >
                                        {t('LOGS')}
                                    </Button>
                                </Tabs>
                            </AppBar>
                            <TabPanel value={auditLoggingTab} index={0}>

                                <Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="dense" size="small"
                                            id="sSplunkHost"
                                            label={t("HOST")}
                                            name="sSplunkHost"
                                            autoComplete="sSplunkHost"
                                            error={state.errors && "s_splunk_host" in state.errors}
                                            helperText={state.errors && "s_splunk_host" in state.errors ? t(state.errors["s_splunk_host"][0]) : t("SPLUNK_HOST_EXPLAINED")}
                                            value={state.sSplunkHost}
                                            onChange={(event) => {
                                                setState({
                                                    sSplunkHost: event.target.value
                                                })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="dense" size="small"
                                            required
                                            id="sSplunkPort"
                                            label={t("PORT")}
                                            type="number"
                                            InputProps={{
                                                inputProps: {
                                                    min: 0,
                                                },
                                            }}
                                            name="sSplunkPort"
                                            autoComplete="sSplunkPort"
                                            error={state.errors && "s_splunk_port" in state.errors}
                                            helperText={state.errors && "s_splunk_port" in state.errors ? t(state.errors["s_splunk_port"][0]) : t("SPLUNK_PORT_EXPLAINED")}
                                            value={state.sSplunkPort}
                                            onChange={(event) => {
                                                setState({
                                                    sSplunkPort: event.target.value
                                                })
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="dense" size="small"
                                            id="sSplunkToken"
                                            label={t("TOKEN")}
                                            name="sSplunkToken"
                                            autoComplete="sSplunkToken"
                                            error={state.errors && "s_splunk_token" in state.errors}
                                            helperText={state.errors && "s_splunk_token" in state.errors ? t(state.errors["s_splunk_token"][0]) : t("SPLUNK_TOKEN_EXPLAINED")}
                                            value={state.sSplunkToken}
                                            onChange={(event) => {
                                                setState({
                                                    sSplunkToken: event.target.value
                                                })
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="dense" size="small"
                                            required
                                            id="sSplunkIndex"
                                            label="Index"
                                            name="sSplunkIndex"
                                            autoComplete="sSplunkIndex"
                                            error={state.errors && "s_splunk_index" in state.errors}
                                            helperText={state.errors && "s_splunk_index" in state.errors ? t(state.errors["s_splunk_index"][0]) : t("SPLUNK_INDEX_EXPLAINED")}
                                            value={state.sSplunkIndex}
                                            onChange={(event) => {
                                                setState({
                                                    sSplunkIndex: event.target.value
                                                })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="dense" size="small"
                                            required
                                            id="sSplunkSourcetype"
                                            label="Sourcetype"
                                            name="sSplunkSourcetype"
                                            autoComplete="sSplunkSourcetype"
                                            error={state.errors && "s_splunk_sourcetype" in state.errors}
                                            helperText={state.errors && "s_splunk_sourcetype" in state.errors ? t(state.errors["s_splunk_sourcetype"][0]) : t("SPLUNK_SOURCETYPE_EXPLAINED")}
                                            value={state.sSplunkSourcetype}
                                            onChange={(event) => {
                                                setState({
                                                    sSplunkSourcetype: event.target.value
                                                })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="dense" size="small"
                                            required
                                            id="sSplunkProtocol"
                                            label={t("PROTOCOL")}
                                            name="sSplunkProtocol"
                                            autoComplete="sSplunkProtocol"
                                            error={state.errors && "s_splunk_protocol" in state.errors}
                                            helperText={state.errors && "s_splunk_protocol" in state.errors ? t(state.errors["s_splunk_protocol"][0]) : t("SPLUNK_PROTOCOL_EXPLAINED")}
                                            value={state.sSplunkProtocol}
                                            onChange={(event) => {
                                                setState({
                                                    sSplunkProtocol: event.target.value
                                                })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>

                                        <FormControl
                                            variant="outlined"
                                            className={classes.textField}
                                            margin="dense" size="small"
                                            required
                                            error={state.errors && "s_splunk_verify" in state.errors}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="sSplunkVerify"
                                                        checked={state.sSplunkVerify}
                                                        onChange={(e) => {
                                                            setState({
                                                                sSplunkVerify: e.target.checked
                                                            })
                                                        }}
                                                    />
                                                }
                                                label={t("TLS_CERTIFICATE_VERIFICATION")}
                                            />
                                            <FormHelperText
                                                className={classes.helperText}
                                            >
                                                {state.errors && "s_splunk_verify" in state.errors
                                                    ? t(state.errors["s_splunk_verify"][0])
                                                    : t("SPLUNK_VERIFY_EXPLAINED")}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={auditLoggingTab} index={1}>

                                <Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="dense" size="small"
                                            id="sS3LoggingBucket"
                                            label="Bucket"
                                            name="sS3LoggingBucket"
                                            autoComplete="sS3LoggingBucket"
                                            error={state.errors && "s_s3_logging_bucket" in state.errors}
                                            helperText={state.errors && "s_s3_logging_bucket" in state.errors ? t(state.errors["s_s3_logging_bucket"][0]) : t("S3_LOGGING_BUCKET_EXPLAINED")}
                                            value={state.sS3LoggingBucket}
                                            onChange={(event) => {
                                                setState({
                                                    sS3LoggingBucket: event.target.value
                                                })
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="dense" size="small"
                                            id="sS3LoggingAccessKeyId"
                                            label="Access key ID"
                                            name="sS3LoggingAccessKeyId"
                                            autoComplete="sS3LoggingAccessKeyId"
                                            error={state.errors && "s_s3_logging_access_key_id" in state.errors}
                                            helperText={state.errors && "s_s3_logging_access_key_id" in state.errors ? t(state.errors["s_s3_logging_access_key_id"][0]) : t("S3_LOGGING_ACCESS_KEY_ID_EXPLAINED")}
                                            value={state.sS3LoggingAccessKeyId}
                                            onChange={(event) => {
                                                setState({
                                                    sS3LoggingAccessKeyId: event.target.value
                                                })
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="dense" size="small"
                                            id="sS3LoggingSecretAccessKey"
                                            label="Secret access key"
                                            name="sS3LoggingSecretAccessKey"
                                            autoComplete="sS3LoggingSecretAccessKey"
                                            error={state.errors && "s_s3_logging_secret_access_key" in state.errors}
                                            helperText={state.errors && "s_s3_logging_secret_access_key" in state.errors ? t(state.errors["s_s3_logging_secret_access_key"][0]) : t("S3_LOGGING_SECRET_ACCESS_KEY_EXPLAINED")}
                                            value={state.sS3LoggingSecretAccessKey}
                                            onChange={(event) => {
                                                setState({
                                                    sS3LoggingSecretAccessKey: event.target.value
                                                })
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={auditLoggingTab} index={2}>

                                <Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="dense" size="small"
                                            required
                                            id="sLogstashTransport"
                                            label={t("LOGSTASH_TRANSPORT")}
                                            name="sLogstashTransport"
                                            autoComplete="sLogstashTransport"
                                            error={state.errors && "s_logstash_transport" in state.errors}
                                            helperText={state.errors && "s_logstash_transport" in state.errors ? t(state.errors["s_logstash_transport"][0]) : t("LOGSTASH_TRANSPORT_EXPLAINED")}
                                            value={state.sLogstashTransport}
                                            onChange={(event) => {
                                                setState({
                                                    sLogstashTransport: event.target.value
                                                })
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="dense" size="small"
                                            id="sLogstashHost"
                                            label={t("HOST")}
                                            name="sLogstashHost"
                                            autoComplete="sLogstashHost"
                                            error={state.errors && "s_logstash_host" in state.errors}
                                            helperText={state.errors && "s_logstash_host" in state.errors ? t(state.errors["s_logstash_host"][0]) : t("LOGSTASH_HOST_EXPLAINED")}
                                            value={state.sLogstashHost}
                                            onChange={(event) => {
                                                setState({
                                                    sLogstashHost: event.target.value
                                                })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            margin="dense" size="small"
                                            required
                                            id="sLogstashPort"
                                            label={t("PORT")}
                                            type="number"
                                            InputProps={{
                                                inputProps: {
                                                    min: 0,
                                                },
                                            }}
                                            name="sLogstashPort"
                                            autoComplete="sLogstashPort"
                                            error={state.errors && "s_logstash_port" in state.errors}
                                            helperText={state.errors && "s_logstash_port" in state.errors ? t(state.errors["s_logstash_port"][0]) : t("LOGSTASH_PORT_EXPLAINED")}
                                            value={state.sLogstashPort}
                                            onChange={(event) => {
                                                setState({
                                                    sLogstashPort: event.target.value
                                                })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl
                                            variant="outlined"
                                            className={classes.textField}
                                            margin="dense" size="small"
                                            required
                                            error={state.errors && "s_logstash_ssl_enabled" in state.errors}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="sLogstashSslEnabled"
                                                        checked={state.sLogstashSslEnabled}
                                                        onChange={(e) => {
                                                            setState({
                                                                sLogstashSslEnabled: e.target.checked
                                                            })
                                                        }}
                                                    />
                                                }
                                                label={t("ENABLE_TLS")}
                                            />
                                            <FormHelperText
                                                className={classes.helperText}
                                            >
                                                {state.errors && "s_logstash_ssl_enabled" in state.errors
                                                    ? t(state.errors["s_logstash_ssl_enabled"][0])
                                                    : t("LOGSTASH_SSL_ENABLED_EXPLAINED")}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl
                                            variant="outlined"
                                            className={classes.textField}
                                            margin="dense" size="small"
                                            required
                                            error={state.errors && "s_logstash_ssl_verify" in state.errors}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="sLogstashSslVerify"
                                                        checked={state.sLogstashSslVerify}
                                                        onChange={(e) => {
                                                            setState({
                                                                sLogstashSslVerify: e.target.checked
                                                            })
                                                        }}
                                                    />
                                                }
                                                label={t("TLS_CERTIFICATE_VERIFICATION")}
                                            />
                                            <FormHelperText
                                                className={classes.helperText}
                                            >
                                                {state.errors && "s_logstash_ssl_verify" in state.errors
                                                    ? t(state.errors["s_logstash_ssl_verify"][0])
                                                    : t("LOGSTASH_SSL_VERIFY_EXPLAINED")}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </Grid>}

                        <Grid item xs={12}>
                            <Typography component="h2" variant="h6" className={classes.h2}>
                                {t("WEBCLIENT_SETTINGS")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                multiline
                                minRows={3}
                                id="cWebclientConfigJson"
                                label={t("CONFIG_JSON")}
                                name="cWebclientConfigJson"
                                autoComplete="cWebclientConfigJson"
                                error={state.errors && "c_webclient_config_json" in state.errors}
                                helperText={state.errors && "c_webclient_config_json" in state.errors ? t(state.errors["c_webclient_config_json"][0]) : ""}
                                value={state.cWebclientConfigJson}
                                onChange={(event) => {
                                    setState({
                                        cWebclientConfigJson: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="h2" variant="h6" className={classes.h2}>
                                {t("ADMIN_PORTAL_SETTINGS")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                multiline
                                minRows={3}
                                id="cPortalConfigJson"
                                label={t("CONFIG_JSON")}
                                name="cPortalConfigJson"
                                autoComplete="cPortalConfigJson"
                                error={state.errors && "c_portal_config_json" in state.errors}
                                helperText={state.errors && "c_portal_config_json" in state.errors ? t(state.errors["c_portal_config_json"][0]) : ""}
                                value={state.cPortalConfigJson}
                                onChange={(event) => {
                                    setState({
                                        cPortalConfigJson: event.target.value
                                    })
                                }}
                            />
                        </Grid>
                    </Grid>

                    <div className={classes.buttons}>
                        <Button variant="contained" color="primary" onClick={onEdit} disabled={!project.permissions.includes('update')}>
                            {t("EDIT")}
                        </Button>
                    </div>
                </Grid>
                {state.variation && state.pricing.hasOwnProperty(state.variation) && (
                    <Grid item xs={12} md={4}>
                        <div className={classes.infoBox}>
                            <Typography component="h2" variant="h6">
                                {t("PER_MONTH_ABOUT")} {state.pricing[state.variation].currency_symbol}{calculateEstimatedMonthlyPrice(state.variation)}
                            </Typography>
                            {t("PSONO_SAAS_INSTANCE_PRICING_INFO")}
                        </div>
                    </Grid>
                )}
            </Grid>
        </ContentBody>
    </>;

}

PsonoSaasInstanceDetail.propTypes = {
    project: PropTypes.object.isRequired,
};

export default PsonoSaasInstanceDetail;
